import { BottomSheet } from '@rio-cloud/rio-uikit';
import { State } from '../../reducers';
import { FunctionProperties, NonFunctionProperties } from '../utils/typescriptutils/typeManipulation';
import { Dispatch } from 'redux';
import { geofenceBottomSheetActions } from './redux/geofenceBottomSheet.reducer';
import { getShowGeofenceBottomSheet } from './redux/geofenceBottomSheet.selector';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';

interface GeofenceBottomSheetProps {
    showGeofenceBottomSheet: boolean;
    onHide: () => void;
}

interface GeofenceWrapperProps {
    titleTranslationId: string;
    contentTranslationId: string;
}

const GeofenceBottomSheet = (
    props: GeofenceBottomSheetProps & GeofenceWrapperProps & WrappedComponentProps
): JSX.Element => {
    const showBottomSheet = props.showGeofenceBottomSheet;
    const intl = props.intl;
    const titleTranslationId = props.titleTranslationId;
    const contentTranslationId = props.contentTranslationId;

    return (
        <BottomSheet
            show={showBottomSheet}
            onClose={props.onHide}
            width={300}
            detach={true}
            bodyClassName="padding-25 margin-right-25 bg-secondary"
            className="bg-secondary"
        >
            <h4>
                <span className="rioglyph rioglyph-click text-size-h4" />{' '}
                {intl.formatMessage({ id: titleTranslationId })}
            </h4>
            <p>{intl.formatMessage({ id: contentTranslationId })}</p>
        </BottomSheet>
    );
};

const mapStateToProps = (state: State): NonFunctionProperties<GeofenceBottomSheetProps> => ({
    showGeofenceBottomSheet: getShowGeofenceBottomSheet(state),
});

const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<GeofenceBottomSheetProps> => ({
    onHide: () => dispatch(geofenceBottomSheetActions.hideGeofenceBottomSheet()),
});

export const GeofenceBottomSheetContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(GeofenceBottomSheet));
