import { deleteGeofence } from './geofenceActions';
import { deletePOI } from './poiActions';
import { getGeofences, getPois } from '../reducers/selectors';
import { Dispatch, State } from '../../types';
import { concatenatePoisAndGeofencesToOldReadModel } from '../table/POIAndGeofenceTable.container';

const isGeofence = (poiOrGeofence: { radius?: number }) => poiOrGeofence.radius;

export const deletePOIOrGeofence = (id: string) => (dispatch: Dispatch, getStore: () => State) => {
    const pois = getPois(getStore());
    const geofences = getGeofences(getStore());
    const poisOrGeofences = concatenatePoisAndGeofencesToOldReadModel(pois, geofences);
    const poiOrGeofence = poisOrGeofences.find((geofence: any) => geofence.geofenceId === id);

    if (isGeofence(poiOrGeofence)) {
        return dispatch(deleteGeofence(id));
    }
    return dispatch(deletePOI(id));
};
