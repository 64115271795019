import { authenticatedGet } from './fetchActions';
import { handleGeoBookedResponse } from '../services/BookingsService';
import { Dispatch } from '../../types';
import { config } from '../../config';

export const fetchGeoBooked = () => (dispatch: Dispatch) => {
    const backend = config.backend.RIO_GEOFENCE_SHARED;
    const url = `${backend}/booked`;
    dispatch(fetchGeoBookedStarted());
    return dispatch(authenticatedGet(url))
        .then((json) => {
            const geoBooked = handleGeoBookedResponse(json);
            dispatch(storeGeoBooked(geoBooked));
        })
        .catch(() => dispatch(fetchGeoBookedFailed()));
};

export const fetchGeoBookedStarted = () => ({
    type: 'FETCH_GEO_BOOKED_STARTED' as 'FETCH_GEO_BOOKED_STARTED',
});

export const fetchGeoBookedFailed = () => ({
    type: 'FETCH_GEO_BOOKED_FAILED' as 'FETCH_GEO_BOOKED_FAILED',
});

export const storeGeoBooked = (geoBooked: boolean) => ({
    type: 'STORE_GEO_BOOKED' as 'STORE_GEO_BOOKED',
    payload: geoBooked,
});
