import { CustomerPOIDto } from './PoiMapper';
import { POI } from '../reducers/poiReducer';

export const mapCategory = (category?: string) => {
    if (!category) {
        return undefined;
    }

    switch (category.toLowerCase().replace('_', ' ')) {
        case 'customer':
            return 'CUSTOMER' as 'CUSTOMER';
        case 'partner':
            return 'PARTNER' as 'PARTNER';
        case 'my company':
            return 'MY_COMPANY' as 'MY_COMPANY';
        case 'my misc/others':
            return 'MISC' as 'MISC';
        default:
            return 'MISC';
    }
};

export const mapToDtoCategory = (category?: string) => {
    if (!category) {
        return undefined;
    }

    switch (category) {
        case 'CUSTOMER' as 'CUSTOMER':
            return 'Customer';
        case 'PARTNER' as 'PARTNER':
            return 'Partner';
        case 'MY_COMPANY' as 'MY_COMPANY':
            return 'My company';
        case 'MISC' as 'MISC':
            return 'My misc/others';
        default:
            return 'My misc/others';
    }
};

export const mapCategoriesFromPOIDtoToPOI = (category: CustomerPOIDto['category']): POI['category'] => {
    switch (category) {
        case 'CUSTOMER':
            return 'CUSTOMER';
        case 'MY_COMPANY':
            return 'MY_COMPANY';
        case 'PARTNER':
            return 'PARTNER';
        case 'MISC':
            return 'MISC';
        default:
            return undefined;
    }
};
