import {loadFromLocalStorage, saveInLocalStorage} from '../../onboarding/handleLocalStorage';
import {geofenceBottomSheet} from './geofenceBottomSheet.reducer';

export const saveIsEnabledBottomSheetState = (value: boolean): void => {
    saveInLocalStorage(geofenceBottomSheet, value);
}

export const loadIsEnabledBottomSheetState = (): boolean => {
    const loadedIsEnabledBottomSheetFlag = loadFromLocalStorage(geofenceBottomSheet);
    return loadedIsEnabledBottomSheetFlag === undefined ? true : loadedIsEnabledBottomSheetFlag;
}