import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { autofill, Field, InjectedFormProps, reduxForm, WrappedFieldProps } from 'redux-form';

import ClearableInput from '../common/formInputs/ClearableInput';
import Select from '../common/formInputs/Select';
import { required } from '../../validation/required';
import POIAddressSearch from './POIAddressSearch.container';
import { fetchPOIAndAutofillForm } from '../../actions/poiActions';
import { Dispatch } from '../../../types';
import { Routing } from '../../app/Routing';
import { DEFAULT_ZOOM } from '../../reducers/mapReducer';
import { Coordinates } from '../../types';
import { setMapCenter, setMapZoom } from '../../actions/mapActions';
import useOnMount from '@rio-cloud/rio-uikit/hooks/useOnMount';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import { getAddressFromQueryString } from '../common/getAddressFromQueryString';

export interface POIFormValues {
    id: string;
    name: string;
    address: {
        label: string;
        position: Coordinates;
    };
    category?: 'CUSTOMER' | 'PARTNER' | 'MY_COMPANY' | 'MISC';
}

export interface PoiCreationFormProps {
    poiId?: string;
    fetchPoi: (id: string, navigate: NavigateFunction) => any;
    autoFillFromQueryString: (search: string) => void;
    setZoom: (zoom: number) => any;
}

export const poiFormElements = {
    formName: 'PoiFormName',
    id: 'id',
    poiName: 'name',
    address: 'address',
    category: 'category',
};
export const PoiForm = (props: PoiCreationFormProps & Partial<InjectedFormProps>) => {
    const { poiId, fetchPoi, autoFillFromQueryString, setZoom } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const editMode = location.pathname.includes('edit');

    useOnMount(() => {
        if (editMode && poiId) {
            fetchPoi(poiId, navigate);
        }
        if (!editMode) {
            autoFillFromQueryString(location.search);
        }
        setZoom(DEFAULT_ZOOM);
    }, []);

    const categoryOptions: Array<object> = [
        { id: 'CUSTOMER', label: <FormattedMessage id={'intl-msg:customer'} /> },
        { id: 'PARTNER', label: <FormattedMessage id={'intl-msg:partner'} /> },
        { id: 'MY_COMPANY', label: <FormattedMessage id={'intl-msg:my-company'} /> },
        { id: 'MISC', label: <FormattedMessage id={'intl-msg:my-misc-others'} /> },
    ];

    const renderAddressDisplay = (addressDisplayFormProps: WrappedFieldProps) => {
        return <input className={'form-control'} value={addressDisplayFormProps.input.value.label} disabled={true} />;
    };

    const renderAddressInput = () => {
        return (
            <div className={'margin-bottom-15'}>
                <label>
                    <FormattedMessage id={'intl-msg:address'} />
                </label>
                <Field
                    name={poiFormElements.address}
                    component={editMode ? renderAddressDisplay : POIAddressSearch}
                    validate={required}
                />
            </div>
        );
    };

    return (
        <form onSubmit={props.handleSubmit}>
            <div className={'margin-bottom-15'}>
                <label>
                    <FormattedMessage id={'fleetmonitor.name'} />
                </label>
                <Field
                    name={poiFormElements.poiName}
                    component={ClearableInput}
                    type={'text'}
                    // placeholderId={'intl-msg:poi.form.name.placeholder'}
                    validate={required}
                />
            </div>
            <div>
                {renderAddressInput()}
                <div className={'margin-bottom-15'}>
                    <label>
                        <FormattedMessage id={'intl-msg:category'} />
                    </label>
                    <Field
                        name={poiFormElements.category}
                        component={Select}
                        options={categoryOptions}
                        placeholderId={'categories'}
                    />
                </div>
            </div>
        </form>
    );
};
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchPoi: (poiId: string, navigate: NavigateFunction) =>
        dispatch(fetchPOIAndAutofillForm(poiId)).catch(() => navigate(Routing.poiList)),
    autoFillFromQueryString: (search: string) => {
        const address = getAddressFromQueryString(search);

        if (address) {
            dispatch(setMapCenter(address.position));
            dispatch(
                autofill(poiFormElements.formName, poiFormElements.address, {
                    label: address.label,
                    position: { lat: address.position.latitude, lng: address.position.longitude },
                })
            );
        }
    },
    setZoom: (zoom: number) => dispatch(setMapZoom(zoom)),
});

const ConnectedPOIForm = connect(undefined, mapDispatchToProps)(PoiForm);

export default reduxForm<POIFormValues, { poiId?: string }>({
    form: poiFormElements.formName,
})(ConnectedPOIForm as any);
