import { connect } from 'react-redux';
import {
    autofill,
    formValueSelector as formValueSelectorCreator,
    isInvalid,
    isPristine,
    isSubmitting,
    submit,
} from 'redux-form';

import { submitCreateGeofence } from '../../actions/geofenceActions';
import { GeofenceSidebar, IGeofenceCreationSidebarProps } from './GeofenceSidebar';
import { showNotification } from '../../actions/notificationActions';
import { testSelfIntersection } from '../../utils/isPolygonSelfIntersecting';
import { Dispatch, State } from '../../../types';
import { Routing } from '../../app/Routing';
import { FunctionProperties, NonFunctionProperties } from '../../utils/typescriptutils/typeManipulation';
import { showDeletePOIDialog } from '../../actions/poiActions';
import { geofenceFormElements } from './GeofenceForm';
import { getAddressFromQueryString } from '../common/getAddressFromQueryString';
import { setMapCenter } from '../../actions/mapActions';
import { NavigateFunction } from 'react-router';

export const mapStateToProps = (state: State): NonFunctionProperties<IGeofenceCreationSidebarProps> => {
    const formValueSelector = formValueSelectorCreator(geofenceFormElements.geofenceFormName);
    const shape = formValueSelector(state, geofenceFormElements.geofenceShape);
    const points = formValueSelector(state, geofenceFormElements.geofencePoints);

    const validateNumberOfPointsOnMap = () => {
        if (shape === 'polygonal') {
            return points.length < 3;
        }
        return points.length < 1;
    };

    const validShape =
        !points || points.length < 4
            ? true
            : !testSelfIntersection(
                  points.map((p: any) => {
                      return { x: p.lng, y: p.lat };
                  })
              );

    const submitDisabled =
        isPristine(geofenceFormElements.geofenceFormName)(state) ||
        isInvalid(geofenceFormElements.geofenceFormName)(state) ||
        isSubmitting(geofenceFormElements.geofenceFormName)(state) ||
        !validShape ||
        validateNumberOfPointsOnMap();

    return {
        submitDisabled,
        mode: 'CREATE' as 'CREATE',
        validShape,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<IGeofenceCreationSidebarProps> => {
    return {
        autofillFromQueryString: (search: string) => {
            const address = getAddressFromQueryString(search);
            if (address) {
                dispatch(setMapCenter(address.position));
                dispatch(
                    autofill(
                        geofenceFormElements.geofenceFormName,
                        geofenceFormElements.geofenceAddress,
                        address.label
                    )
                );
                dispatch(
                    autofill(
                        geofenceFormElements.geofenceFormName,
                        geofenceFormElements.geofencePoints,
                        [{ lat: address.position.latitude, lng: address.position.longitude }]
                    )
                );
            }
        },
        onClose: (navigate: NavigateFunction) => {
            navigate(Routing.poiList);
        },
        onDelete: (geofenceId: string) => dispatch(showDeletePOIDialog(geofenceId)),
        onSubmitForm: (navigate: NavigateFunction) =>
            dispatch(submitCreateGeofence())
                .then(() => navigate(Routing.poiList))
                .then(() => dispatch(showNotification('intl-msg:geofence.create.successNotification', 'success')))
                .catch(() => dispatch(showNotification('intl-msg:geofence.create.errorNotification', 'error'))),
        onClickSubmit: () => dispatch(submit(geofenceFormElements.geofenceFormName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeofenceSidebar);
