import { AuthorizationError } from './authorizationError';
import { ForbiddenError } from './forbiddenError';
import { RemoteServerError } from './remoteServerError';
import { NotFoundError } from './notFoundError';
import { AccessToken } from '../../configuration';

const getAuthorization = (token: AccessToken) => `Bearer ${token}`;

export const getFetchOptions = (token?: AccessToken, method = 'GET') => ({
    method,
    headers: {
        Authorization: token ? getAuthorization(token) : '',
    },
});

const rejectUnauthorizedRequests = (response: any) => {
    if (response.status === 401) {
        throw new AuthorizationError();
    }
    return response;
};

const rejectForbiddenRequests = (response: any) => {
    if (response.status === 403) {
        throw new ForbiddenError();
    }
    return response;
};

const rejectErroneousRequests = (response: any) => {
    if (!response.ok) {
        const errorMessage = `received response with status code ${response.status} (${response.statusText})`;
        throw new RemoteServerError(errorMessage, response);
    }
    return response;
};

const rejectNotFoundRequests = (response: any) => {
    if (response.status === 404) {
        throw new NotFoundError();
    }
    return response;
};

const fetchJSON = (url = '', opts: RequestInit = {}) => {
    return fetchResponse(url, opts).then((response) => response.json());
};

export const fetchResponse = (url = '', opts: RequestInit = {}) => {
    const headers = { accept: 'application/json', 'Content-Type': 'application/json', ...opts.headers };
    const options = { ...opts, headers };

    return fetch(url, options)
        .then(rejectUnauthorizedRequests)
        .then(rejectForbiddenRequests)
        .then(rejectNotFoundRequests)
        .then(rejectErroneousRequests);
};

export default fetchJSON;
