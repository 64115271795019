import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';

import GeofenceCreationForm from './GeofenceForm';
import GeofenceSidebarFooter from './GeofenceSidebarFooter';
import useOnMount  from '@rio-cloud/rio-uikit/hooks/useOnMount';
import { Location } from 'history';
import { validate } from 'uuid';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';

export interface IGeofenceCreationSidebarProps {
    mode: 'CREATE' | 'UPDATE';
    autofillFromQueryString: (search: string) => void;
    onDelete: (geofenceId: string) => void;
    onClose: (navigate: NavigateFunction) => void;
    onSubmitForm: (navigate: NavigateFunction) => void;
    onClickSubmit: () => void;
    submitDisabled: boolean;
    validShape?: boolean;
}

const resolveGeofenceId = (location: Location): string | undefined => {
    const possibleGeofenceId = location.pathname.split('/').pop();
    if (possibleGeofenceId && validate(possibleGeofenceId)) {
        return possibleGeofenceId;
    } else {
        return undefined;
    }
};

export const GeofenceSidebar = (props: IGeofenceCreationSidebarProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const geofenceId = resolveGeofenceId(location);

    useOnMount(() => {
        props.autofillFromQueryString(location.search);
    }, []);

    const handleDelete = () => {
        if (geofenceId) props.onDelete(geofenceId);
    };

    const handleCLose = () => {
        props.onClose(navigate);
    };

    const handleOnSubmit = () => {
        props.onSubmitForm(navigate);
    };

    return (
        <Sidebar
            width={400}
            title={
                props.mode === 'CREATE' ? (
                    <FormattedMessage id={'intl-msg:geofence.create.title'} />
                ) : (
                    <FormattedMessage id={'intl-msg:geofence.edit.title'} />
                )
            }
            onClose={handleCLose}
            closed={false}
            titleClassName={'text-size-large margin-left-10'}
            footer={
                <GeofenceSidebarFooter
                    onDeleteHandler={handleDelete}
                    onAbortHandler={handleCLose}
                    onSubmitHandler={props.onClickSubmit}
                    submitDisabled={props.submitDisabled}
                    mode={props.mode}
                />
            }
        >
            <div className={'padding-20 padding-top-0'}>
                <GeofenceCreationForm validShape={props.validShape} geofenceId={geofenceId} onSubmit={handleOnSubmit} />
            </div>
        </Sidebar>
    );
};
