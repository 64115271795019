import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { AccessTokenState, configReducer, langReducer, LanguageState, loginReducer, LoginState } from './configuration';
import tokenHandlingReducer from './configuration/tokenHandling/reducer'; // TODO make import from ./configuration work
import appReducer, { AppState } from './features/reducers/appReducer';
import { ConfigState } from './config';
import poiReducer from './features/reducers/poiReducer';
import mapReducer from './features/reducers/mapReducer';
import { geofenceReducer } from './features/reducers/geofenceReducer';
import bookingsReducer from './features/reducers/bookingsReducer';
import { uploadReducer } from './features/reducers/uploadReducer';
import { positionSharingReducer } from './features/positionSharingDialog/redux/positionSharing.redux';
import { onboardingReducer } from './features/onboarding/redux/oboarding.redux';
import { geofenceBottomSheetReducer } from './features/map/redux/geofenceBottomSheet.reducer';
import { hereAutoSuggestApi } from './features/components/common/addressSearch/hereAutoSuggestApi';

export interface State {
    tokenHandling?: AccessTokenState;
    login?: LoginState;
    lang?: LanguageState;
    config?: ConfigState;
    app: AppState;
    form: ReturnType<typeof formReducer>;
    poi: ReturnType<typeof poiReducer>;
    newMap: ReturnType<typeof mapReducer>;
    geofence: ReturnType<typeof geofenceReducer>;
    upload: ReturnType<typeof uploadReducer>;
    bookings: ReturnType<typeof bookingsReducer>;
    positionSharing: ReturnType<typeof positionSharingReducer>;
    onboarding: ReturnType<typeof onboardingReducer>;
    geofenceBottomSheet: ReturnType<typeof geofenceBottomSheetReducer>;
    [hereAutoSuggestApi.reducerPath]: ReturnType<typeof hereAutoSuggestApi.reducer>;
}

export const rootReducer = () =>
    combineReducers<State>({
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenHandlingReducer as any,
        poi: poiReducer,
        form: formReducer,
        newMap: mapReducer,
        geofence: geofenceReducer,
        upload: uploadReducer,
        bookings: bookingsReducer,
        positionSharing: positionSharingReducer,
        onboarding: onboardingReducer,
        geofenceBottomSheet: geofenceBottomSheetReducer,
        [hereAutoSuggestApi.reducerPath]: hereAutoSuggestApi.reducer,
    });
