import { connect } from 'react-redux';
import { arrayRemoveAll, change, clearFields, formValueSelector } from 'redux-form';

import { setMapCenter } from '../../../actions/mapActions';
import { AddressSearch, IEvent } from '../../common/addressSearch/AddressSearch';
import { geofenceFormElements } from '../GeofenceForm';
import { mapToPosition } from './utils/hereMapHelper';
import { Dispatch, State } from '../../../../types';
import { getDisplayMessages, getLocale } from '../../../../configuration';
import { getMapCenter } from '../../../reducers/selectors';
import { injectIntl } from 'react-intl';
import { config } from '../../../../config';

export const mapStateToProps = (state: State) => {
    const hereApiKey = config.hereApiKey;
    const userLocale = getLocale(state);
    return {
        hereApiKey,
        shortLocale: userLocale ? userLocale.split('-')[0] : '',
        value: formValueSelector(geofenceFormElements.geofenceFormName)(state, geofenceFormElements.geofenceAddress),
        currentMapCenter: getMapCenter(state),
        displayMessages: getDisplayMessages(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChange: (event: IEvent | null) => {
        if (event) {
            dispatch(setMapCenter(mapToPosition(event.latitude, event.longitude)));
            dispatch(change(geofenceFormElements.geofenceFormName, geofenceFormElements.geofenceAddress, event.label));
            dispatch(
                change(geofenceFormElements.geofenceFormName, geofenceFormElements.geofencePoints, [
                    { lat: event.latitude, lng: event.longitude },
                ])
            );
            dispatch(
                clearFields(
                    geofenceFormElements.geofenceFormName,
                    false,
                    false,
                    geofenceFormElements.geofenceSuggestionPoint
                )
            );

            if (!event.label) {
                dispatch(change(geofenceFormElements.geofenceFormName, geofenceFormElements.geofenceAddress, ''));
                dispatch(arrayRemoveAll(geofenceFormElements.geofenceFormName, 'points'));
            }
        } else {
            dispatch(
                clearFields(
                    geofenceFormElements.geofenceFormName,
                    false,
                    false,
                    geofenceFormElements.geofenceAddress,
                    geofenceFormElements.geofencePoints
                )
            );
        }
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddressSearch));
