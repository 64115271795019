export const HIDE_SESSION_EXPIRED_DIALOG = 'app/HIDE_SESSION_EXPIRED_DIALOG';

interface HideSessionExpiredAction {
    type: typeof HIDE_SESSION_EXPIRED_DIALOG;
}

export type AppActions = HideSessionExpiredAction | ReturnType<typeof setEditEntity> | ReturnType<typeof setEditMode>;

export const hideSessionExpiredDialog = () => {
    return {
        type: HIDE_SESSION_EXPIRED_DIALOG,
    };
};

export const setEditMode = (editMode?: 'CREATE' | 'UPDATE') => ({
    type: 'SET_EDIT_MODE' as 'SET_EDIT_MODE',
    payload: editMode,
});

export const setEditEntity = (editEntity?: 'POI' | 'GEOFENCE') => ({
    type: 'SET_EDIT_ENTITY' as 'SET_EDIT_ENTITY',
    payload: editEntity,
});
