import React from 'react';
import { FormattedMessage } from 'react-intl';

import { trackingHelper, Trigger } from '../../tracking/setupTracking';
import { Routing } from '../../app/Routing';

export interface IGeofenceCreationSidebarFooterProps {
    mode?: 'CREATE' | 'UPDATE';
    onDeleteHandler: () => void;
    onAbortHandler: () => void;
    onSubmitHandler: () => void;
    submitDisabled: boolean;
}

const isPoiOrGeofenceMode = () => {
    const currentLocation = window.location.href;
    if (currentLocation) {
        if (currentLocation.includes(Routing.poiCreate) || currentLocation.includes(Routing.poiEdit.split(':')[0])) {
            return 'poi';
        }
        if (
            currentLocation.includes(Routing.geofenceCreate) ||
            currentLocation.includes(Routing.geofenceEdit.split(':')[0])
        ) {
            return 'geofences';
        }
    }
    return 'unknown';
};

class GeofenceSidebarFooter extends React.Component<IGeofenceCreationSidebarFooterProps> {
    render() {
        const tracking = trackingHelper(`geofencing, ${isPoiOrGeofenceMode()}`);

        return (
            <React.Fragment>
                <div className={'btn-toolbar pull-left'}>
                    {this.props.mode === 'UPDATE' ? (
                        <button
                            type={'button'}
                            className={'btn btn-link btn-danger'}
                            onClick={this.props.onDeleteHandler}
                        >
                            <span className={'rioglyph rioglyph-trash text-color-danger margin-right-5'} />
                            <FormattedMessage id={'intl-msg:delete'} />
                        </button>
                    ) : null}
                </div>
                <div className={'btn-toolbar pull-right'}>
                    <button
                        type={'button'}
                        className={'btn btn-default margin-right-5'}
                        onClick={this.props.onAbortHandler}
                        {...tracking.getTrackingDataLabels(Trigger.click, 'cancel', `mode::${this.props.mode}`)}
                    >
                        <FormattedMessage id={'fleetmonitor.abort'} />
                    </button>
                    <button
                        className={'btn btn-primary'}
                        type={'submit'}
                        disabled={this.props.submitDisabled}
                        onClick={this.props.onSubmitHandler}
                        {...tracking.getTrackingDataLabels(Trigger.click, 'submit', `mode::${this.props.mode}`)}
                    >
                        {this.props.mode === 'UPDATE' ? (
                            <FormattedMessage id={'intl-msg:geofence.edit.submit'} />
                        ) : (
                            <FormattedMessage id={'intl-msg:geofence.create.submit'} />
                        )}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

export default GeofenceSidebarFooter;
