import { connect } from 'react-redux';

import { setMapCenter } from '../../../actions/mapActions';
import { AddressSearch, IEvent } from '../../common/addressSearch/AddressSearch';
import { mapToPosition } from './utils/hereMapHelper';
import { Dispatch } from '../../../../types';
import { mapStateToProps } from './CircleFormAddressSearchContainer';
import { injectIntl } from 'react-intl';

export const mapDispatchToProps = (dispatch: Dispatch) => {
    const onChange = (event: IEvent | null) =>
        event && dispatch(setMapCenter(mapToPosition(event.latitude, event.longitude)));

    return {
        onChange,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddressSearch));
