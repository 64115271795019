import * as React from 'react';

import { LegacyPOI } from './POIAndGeofenceTable';
import { IntlShape } from 'react-intl';

const isPolygonalGeofence = (radius: number) => {
    return radius < 0;
};

const isPoi = (poi: LegacyPOI) => !poi.radius;

export const typeFormatter = (radiusWithUnit: string | undefined, entry: LegacyPOI, intl: IntlShape) => {
    if (!radiusWithUnit) {
        return (
            <div>
                <span title={intl.formatMessage({id: 'table.type.poi'})} className={'rioglyph rioglyph-poi text-size-large text-color-dark'} />
            </div>
        );
    }
    if (isPolygonalGeofence(entry.radiusSort)) {
        return (
            <div>
                <span title={intl.formatMessage({id: 'table.type.geofence.polygonal'})} className={'rioglyph rioglyph-polygon text-size-large text-color-dark'} />
            </div>
        );
    }
    return (
        <div>
            <span title={intl.formatMessage({id: 'table.type.geofence.circular'})} className={'rioglyph rioglyph-sphere margin-right-5 text-size-large text-color-dark'} />
            <span className={'text-color-dark'}>( {radiusWithUnit} )</span>
        </div>
    );
};

export const categoryFormatter = (category: string) => {
    return category && <span className={'tag tag-small'}>{category}</span>;
};

export const createIcon = (
    glyphSelector: string,
    onClick: (event: React.MouseEvent<HTMLElement>) => void,
    disabled: boolean,
    testid: string
) => {
    return (
        <button className={'btn btn-link'} onClick={onClick} disabled={disabled} data-testid={testid}>
            <span className={`rioglyph ${glyphSelector} margin-0`} />
        </button>
    );
};

export const createClickHandler = (geofenceId: string, callback: (id: string) => void) => {
    return (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        callback(geofenceId);
    };
};

export const createButtonFormatter = (
    onDelete: (id: string) => void,
    onPoiUpdate: (id: string) => void,
    onGeofenceUpdate: (id: string) => void,
    allowedToEdit: boolean,
    allowedToDelete: boolean,
    geofenceId: string,
    poi: LegacyPOI
) => {
    const editClickHandler = isPoi(poi)
        ? createClickHandler(geofenceId, onPoiUpdate)
        : createClickHandler(geofenceId, onGeofenceUpdate);
    const createTestId = (type: string): string => {
        return isPoi(poi) ? `poi:${type}:${geofenceId}` : `geofence:${type}:${geofenceId}`;
    }
    return (
        <div>
            {createIcon('rioglyph-pencil', editClickHandler, !allowedToEdit, createTestId('edit'))}
            {createIcon('rioglyph-trash', createClickHandler(geofenceId, onDelete), !allowedToDelete, createTestId('delete'))}
        </div>
    );
};
