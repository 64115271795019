interface Point {
    x: number;
    y: number;
}

// Given three colinear points p, q, r, the function checks if
// point q lies on line segment 'pr'
const onSegment = (p: Point, q: Point, r: Point) => {
    if (
        q.x <= Math.max(p.x, r.x) &&
        q.x >= Math.min(p.x, r.x) &&
        q.y <= Math.max(p.y, r.y) &&
        q.y >= Math.min(p.y, r.y)
    ) {
        return true;
    } else {
        return false;
    }
};

// To find orientation of ordered triplet (p, q, r).
// The function returns following values
// 0 --> p, q and r are colinear
// 1 --> Clockwise
// 2 --> Counterclockwise
const getOrientation = (p: Point, q: Point, r: Point) => {
    // See https://www.geeksforgeeks.org/orientation-3-ordered-points/
    // for details of below formula.
    const val: number = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);

    if (val === 0) {
        return 0;
    } // colinear

    return val > 0 ? 1 : 2; // clock or counterclock wise
};

// The main function that returns true if line segment 'p1q1'
// and 'p2q2' intersect.
const doIntersect = (p1: Point, q1: Point, p2: Point, q2: Point) => {
    // Find the four orientations needed for general and
    // special cases
    const o1 = getOrientation(p1, q1, p2);
    const o2 = getOrientation(p1, q1, q2);
    const o3 = getOrientation(p2, q2, p1);
    const o4 = getOrientation(p2, q2, q1);

    // General case
    if (o1 !== o2 && o3 !== o4) {
        return true;
    }

    // Special Cases
    // p1, q1 and p2 are colinear and p2 lies on segment p1q1
    if (o1 === 0 && onSegment(p1, p2, q1)) {
        return true;
    }

    // p1, q1 and q2 are colinear and q2 lies on segment p1q1
    if (o2 === 0 && onSegment(p1, q2, q1)) {
        return true;
    }

    // p2, q2 and p1 are colinear and p1 lies on segment p2q2
    if (o3 === 0 && onSegment(p2, p1, q2)) {
        return true;
    }

    // p2, q2 and q1 are colinear and q1 lies on segment p2q2
    if (o4 === 0 && onSegment(p2, q1, q2)) {
        return true;
    }

    return false; // Doesn't fall in any of the above cases
};

const testSelfIntersection = (points: Point[]) => {
    let intersecting = false;
    points.forEach((point, index) => {
        if (index >= points.length) {
            return;
        }
        const p1 = point;
        const q1 = index === points.length - 1 ? points[0] : points[index + 1];
        points.forEach((otherPoint, otherIndex) => {
            if (
                otherIndex !== index &&
                otherIndex !== index - 1 &&
                otherIndex !== index + 1 &&
                !(index === 0 && otherIndex === points.length - 1) &&
                !(otherIndex === 0 && index === points.length - 1) &&
                otherIndex < points.length
            ) {
                const p2 = otherPoint;
                const q2 = otherIndex === points.length - 1 ? points[0] : points[otherIndex + 1];
                if (doIntersect(p1, q1, p2, q2)) {
                    intersecting = true;
                }
            }
        });
    });
    return intersecting;
};

export { testSelfIntersection };
