import { connect } from 'react-redux';

import { mapPOIToTableObject } from '../mapper/PoiMapper';
import { mapGeofenceToTableObject } from '../mapper/GeofenceMapper';
import {
    getGeofences,
    getIsGeofencesLoading,
    getIsPoisLoading,
    getPois,
    isGeofenceActionAllowed,
} from '../reducers/selectors';
import { AllowedListAction } from '../reducers/geofenceReducer';

import { POIAndGeofenceTable } from './POIAndGeofenceTable';
import { Dispatch, State } from '../../types';
import { Routing } from '../app/Routing';
import { fetchPOIs } from '../actions/poiActions';
import { getGeofences as fetchGeofences } from '../actions/geofenceActions';
import { injectIntl } from 'react-intl';
import { NavigateFunction, useNavigate } from 'react-router';
import React from 'react';

export const withRouter = <ComponentProps,>(Component: React.FunctionComponent<ComponentProps>) => {
    return (props: ComponentProps) => {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
};

export const concatenatePoisAndGeofencesToOldReadModel = (fetchedPois: any, fetchedGeofences: any) => {
    const mappedPois = fetchedPois.map((poi: any) => mapPOIToTableObject(poi));
    const mappedGeofences = fetchedGeofences.map((geofence: any) => mapGeofenceToTableObject(geofence));
    return mappedPois.concat(mappedGeofences);
};

export const mapStateToProps = (state: State) => {
    const pois = getPois(state);
    const geofences = getGeofences(state);
    return {
        isPoisLoading: getIsPoisLoading(state),
        isGeofencesLoading: getIsGeofencesLoading(state),
        listOfPOIsAndGeofences: concatenatePoisAndGeofencesToOldReadModel(pois, geofences),
        allowedToCreateGeofence: isGeofenceActionAllowed(state, AllowedListAction.GEOFENCE_CREATE),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        geofenceEditHandler: (geofenceId: string, navigate: NavigateFunction) =>
            navigate(Routing.geofenceEdit.replace(':id', geofenceId)),
        refreshPoiTableData: () => {
            dispatch(fetchPOIs());
            dispatch(fetchGeofences());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(POIAndGeofenceTable)));
