export enum Trigger {
    click = 'click',
    visibility = 'visibility',
}

interface TrackingDataLabels {
    'data-track-ga-event-trigger': string;
    'data-track-ga-event-category': string;
    'data-track-ga-event-action': string;
    'data-track-ga-event-label'?: string;
    'data-track-ga-event-value'?: number;
}

export const trackingHelper = (category: string) => ({
    getTrackingDataLabels: (trigger: Trigger, action: string, label?: string, value?: number) => {
        const returnValue: TrackingDataLabels = {
            'data-track-ga-event-trigger': trigger,
            'data-track-ga-event-category': category,
            'data-track-ga-event-action': action,
        };
        if (value !== undefined) {
            returnValue['data-track-ga-event-value'] = value;
        }

        if (label !== undefined) {
            returnValue['data-track-ga-event-label'] = label;
        }

        return returnValue;
    },
});
