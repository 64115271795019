import { poiDeleted, poiFetched, poisFetched, showDeletePOIDialog, hideDeletePOIDialog } from '../actions/poiActions';

export interface POI {
    id: string;
    name: string;
    address: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    category?: 'CUSTOMER' | 'PARTNER' | 'MY_COMPANY' | 'MISC';
    type: 'CUSTOMER' | 'WORKSHOP';
    metadata: {
        created_at: string;
        modified_at: string;
    };
}

export interface IPOIState {
    pois: {
        [id: string]: POI;
    };
    isPoisLoading: boolean;
    deletePoiGeofenceId?: string;
}

const INITIAL_STATE = {
    pois: {},
    isPoisLoading: true,
    deletePoiGeofenceId: undefined,
};

const removePoi = (id: string, pois: IPOIState['pois']) => {
    const { [id]: poi, ...restOfPois } = pois;
    return restOfPois;
};

export type handledActions =
    | ReturnType<typeof poiFetched>
    | ReturnType<typeof poiDeleted>
    | ReturnType<typeof poisFetched>
    | ReturnType<typeof showDeletePOIDialog>
    | ReturnType<typeof hideDeletePOIDialog>;

export const poiReducer = (state: IPOIState = INITIAL_STATE, action: handledActions) => {
    switch (action.type) {
        case 'POI_FETCHED':
            const poi = action.payload;
            return { ...state, pois: { ...state.pois, [poi.id]: poi } };
        case 'POIS_FETCHED':
            const pois: {
                [id: string]: POI;
            } = {};
            action.payload.forEach((item) => pois[item.id] = item);
            return { ...state, pois, isPoisLoading: false };
        case 'POI_DELETED':
            return {
                ...state,
                pois: removePoi(action.payload.id, state.pois),
            };
        case 'SHOW_DELETE_POI_DIALOG':
            return {
                ...state,
                deletePoiGeofenceId: action.payload,
            };
        case 'HIDE_DELETE_POI_DIALOG':
            return {
                ...state,
                deletePoiGeofenceId: undefined,
            };
        default:
            return state;
    }
};

export const getPoi = (state: IPOIState, poiId: string): POI | undefined => state.pois[poiId];
export const getPois = (state: IPOIState): POI[] | undefined => Object.values(state.pois);
export const getIsPoisLoading = (state: IPOIState) => state.isPoisLoading;

export default poiReducer;
