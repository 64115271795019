const localStorage = window.localStorage;
const STORAGE_PREFIX = 'geofence-administration';

export const saveInLocalStorage = (key: string, value: Object): void => {
    try {
        localStorage.setItem(`${STORAGE_PREFIX}.${key}`, JSON.stringify(value));
    } catch (_) {
        /* Intentionally left blank */
    }
};

export const loadFromLocalStorage = (key: string): any => {
    try {
        const item = localStorage.getItem(`${STORAGE_PREFIX}.${key}`);
        if (item) {
            return JSON.parse(item);
        }
    } catch (_) {
        /* Intentionally left blank */
    }
};
