import { arrayInsert, arrayRemove, arraySplice, autofill, change, clearFields, formValueSelector } from 'redux-form';
import { Dispatch, State } from '../../types';
import { geofenceFormElements } from '../components/geofence/GeofenceForm';
import { poiFormElements } from '../components/poi/PoiForm';
import { BoundingBox } from '../reducers/mapReducer';
import { getEditEntity, getEditMode } from '../reducers/selectors';
import { calculateNewIndex } from '../utils/geofencePointIndexCalculator';
import { Coordinates, PolygonalGeofenceCoordinates } from '../types';
import { geofenceBottomSheetActions } from '../map/redux/geofenceBottomSheet.reducer';
import { store } from '../../configuration';
import { EnhancedEvent } from 'rio-uikit-map';

export interface MapPosition {
    longitude: number;
    latitude: number;
}

export const setMapBoundingBox = (bbox: BoundingBox) => ({
    type: 'ACTION_SET_MAP_BOUNDING_BOX' as 'ACTION_SET_MAP_BOUNDING_BOX',
    payload: bbox,
});

export const setMapCenter = (position: MapPosition) => ({
    type: 'ACTION_SET_MAP_CENTER' as 'ACTION_SET_MAP_CENTER',
    payload: position,
});

export const setMapZoom = (zoom: number) => ({
    type: 'ACTION_SET_MAP_ZOOM' as 'ACTION_SET_MAP_ZOOM',
    payload: zoom,
});

export const clickOnMap = (point: Coordinates) => (dispatch: Dispatch, getStore: () => State) => {
    const fieldPathGeofenceShape = `${geofenceFormElements.geofenceShape}`;
    const formName = geofenceFormElements.geofenceFormName;
    const shape = formValueSelector(formName)(getStore(), fieldPathGeofenceShape);

    if (shape === 'polygonal') {
        const points = formValueSelector(formName)(getStore(), geofenceFormElements.geofencePoints);
        const added = points ? points.length : 0;
        if (added >= 2) {
            store.dispatch(geofenceBottomSheetActions.showGeofenceBottomSheet());
        }
        dispatch(
            arrayInsert(formName, geofenceFormElements.geofencePoints, calculateNewIndex(point, points), {
                ...point,
                added,
            })
        );
    } else if (shape === 'circular') {
        dispatch(autofill(formName, geofenceFormElements.geofenceAddress, point.lat + ', ' + point.lng));
        dispatch(change(geofenceFormElements.geofenceFormName, geofenceFormElements.geofencePoints, [point]));
        dispatch(clearFields(formName, true, false, geofenceFormElements.geofenceSuggestionPoint));
    }
};

export const dragGeofencePoint = (point: Coordinates, index: number) => (dispatch: Dispatch, getStore: () => State) => {
    const points = formValueSelector(geofenceFormElements.geofenceFormName)(
        getStore(),
        geofenceFormElements.geofencePoints
    );
    const added = points[index].added;
    dispatch(arraySplice(geofenceFormElements.geofenceFormName, 'points', index, 1, { ...point, added }));
};

export const undoPolygonPoint = () => (dispatch: Dispatch, getStore: () => State) => {
    const points: Array<PolygonalGeofenceCoordinates> = formValueSelector(geofenceFormElements.geofenceFormName)(
        getStore(),
        geofenceFormElements.geofencePoints
    );
    const lastPoint = points.findIndex((point: PolygonalGeofenceCoordinates) => point.added === points.length - 1);
    dispatch(arrayRemove(geofenceFormElements.geofenceFormName, geofenceFormElements.geofencePoints, lastPoint));
};

export const clickOnPoiMap = (point: Coordinates) => (dispatch: Dispatch) => {
    dispatch(
        autofill(poiFormElements.formName, poiFormElements.address, {
            label: point.lat + ', ' + point.lng,
            position: point,
        })
    );
};

export const handleTapEvent = (enhancedMapEvent: EnhancedEvent) => (dispatch: Dispatch, getStore: () => State) => {
    const state = getStore();
    const editEntity = getEditEntity(state);
    const editMode = getEditMode(state) === 'UPDATE';
    if (editMode) {
        return;
    }
    if (editEntity === 'GEOFENCE') {
        const point = enhancedMapEvent.calculateGeoCoords();
        dispatch(clickOnMap({lat: point.lat, lng: point.lng}));
    }
    if (editEntity === 'POI') {
        const point = enhancedMapEvent.calculateGeoCoords();
        dispatch(clickOnPoiMap({lat: point.lat, lng: point.lng}));
    }
};
