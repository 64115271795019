import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { default as RadioButtonUikit } from '@rio-cloud/rio-uikit/RadioButton';

export interface IProps {
    defaultChecked: boolean;
    radioLabel?: React.ReactNode;
}

class RadioButton extends React.Component<WrappedFieldProps & IProps> {
    render() {
        const {
            input: { value, onChange, name },
            defaultChecked,
            radioLabel,
        } = this.props;

        return (
            <RadioButtonUikit
                value={value}
                onClick={onChange}
                defaultChecked={defaultChecked}
                name={name}
                inline={true}
            >
                {radioLabel}
            </RadioButtonUikit>
        );
    }
}

export default RadioButton;
