import * as React from 'react';

import { type AutoSuggestSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';
import { WrappedComponentProps } from 'react-intl';
import { SuggestAddress } from './SuggestAddress';

export interface IEvent extends AutoSuggestSuggestion {
    label: string;
    title: string;
    vicinity?: string;
    latitude: number;
    longitude: number;
}

interface AddressSearchProps {
    hereApiKey: string;
    onChange: (event: IEvent | null) => void;
    shortLocale: string;
    maxResults: number;
    value?: string;
    currentMapCenter?: {
        lng: number;
        lat: number;
    };
}

export class AddressSearch extends React.PureComponent<AddressSearchProps & WrappedComponentProps> {
    static defaultProps = {
        shortLocale: 'en',
        maxResults: 5,
    };

    render() {
        return (
            <div className={'AddressSuggest'}>
                <SuggestAddress
                    address={{ label: this.props.value }}
                    placeholder={this.props.intl.formatMessage({ id: 'fleetmonitor.doInputAndSelectSuggestion' })}
                    language={this.props.shortLocale}
                    maxResults={this.props.maxResults}
                    onChange={this.props.onChange}
                    icon={'rioglyph-search'}
                    hereApiKey={this.props.hereApiKey}
                    currentMapCenter={this.props.currentMapCenter}
                    intl={this.props.intl}
                />
            </div>
        );
    }
}
