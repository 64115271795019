const EARTH_CIRCUMFERENCE_METERS = 40075017;

/**
 * Transforms local coordinates relative to a given reference point to global coordinates
 * @param localCoordinates cartesian coordinates in meters relative to the given reference point
 * @param referencePoint as global coordinates (lng in [-180, 180], lat in ]-90, 90[ )
 *
 * @returns global coordinates
 *
 * @throws Error if reference point is invalid
 */
export const transformLocalToGlobalCoordinates = (
    localCoordinates: { x: number; y: number },
    referencePoint: { lng: number; lat: number }
) => {
    if (
        referencePoint.lat <= -90 ||
        referencePoint.lat >= 90 ||
        referencePoint.lng < -180 ||
        referencePoint.lng > 180
    ) {
        throw new Error('Invalid reference point');
    }

    const relativeLat = (localCoordinates.y / EARTH_CIRCUMFERENCE_METERS) * 360;
    const relativeLng =
        (localCoordinates.x * 360) / EARTH_CIRCUMFERENCE_METERS / Math.cos((Math.PI * referencePoint.lat) / 180);
    const coordinates = { lng: referencePoint.lng + relativeLng, lat: referencePoint.lat + relativeLat };

    if (coordinates.lat >= 90 || coordinates.lat <= -90) {
        throw new Error('Transformed coordinates are out of bounds');
    }

    if (coordinates.lng > 180) {
        coordinates.lng -= 360;
    } else if (coordinates.lng < -180) {
        coordinates.lng += 360;
    }
    return coordinates;
};
