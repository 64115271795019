// tslint:disable-next-line:no-submodule-imports
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HerePlace } from './SuggestAddress';
import { AutoSuggestSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';

interface HereAutosuggestApiParams {
    value: string;
    maxResults: number;
    hereApiKey: string;
    language: string;
    currentMapCenter?: {
        lng: number;
        lat: number;
    };
}

interface HereAutoSuggestResponse {
    items: Array<HerePlace>;
}

const convertPlaceToSuggestion = (place: HereAutoSuggestResponse): Array<AutoSuggestSuggestion> => {
    return place.items
        .filter((item) => item.position)
        .map((item) => ({
        title: item.title,
        label: item.address.label,
        latitude: item.position!!.lat,
        longitude: item.position!!.lng,
    }));
};

const createHereAutoSuggestUrl = (params: HereAutosuggestApiParams): string => {
    const { value, currentMapCenter, maxResults, hereApiKey, language } = params;
    const query = `q=${encodeURIComponent(value)}`;
    const at = currentMapCenter ? `at=${currentMapCenter.lat},${currentMapCenter.lng}` : '';

    return `autosuggest?${at}&${query}&limit=${maxResults}&apiKey=${hereApiKey}&lang=${language}`;
};

export const hereAutoSuggestApi = createApi({
    reducerPath: 'hereAutoSuggestApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://autosuggest.search.hereapi.com/v1/',
    }),
    endpoints: (builder) => ({
        getAutosuggestion: builder.query<Array<AutoSuggestSuggestion>, HereAutosuggestApiParams>({
            query: (params) => createHereAutoSuggestUrl(params),
            transformResponse: convertPlaceToSuggestion,
        }),
    }),
});

export const { useGetAutosuggestionQuery } = hereAutoSuggestApi;
