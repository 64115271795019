export interface ConfigState {
    backend: {
        MENU_SERVICE: string | undefined;
        RIO_MAPSERVICE: string | undefined;
        RIO_POISERVICE: string | undefined;
        RIO_GEOFENCE_SHARED: string | undefined;
        RIO_GEOFENCEADMIN: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: Array<string>;
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    sentryToken: string;
    configCat: {
        apiKey: string;
    };
    hereBaseUrl: string;
    hereApiKey: string;
}

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        RIO_MAPSERVICE: import.meta.env.VITE_RIO_MAPSERVICE,
        RIO_POISERVICE: import.meta.env.VITE_RIO_POISERVICE,
        RIO_GEOFENCE_SHARED: import.meta.env.VITE_RIO_GEOFENCE_SHARED,
        RIO_GEOFENCEADMIN: import.meta.env.VITE_RIO_GEOFENCEADMIN,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '08232e43-21dd-4131-ab57-7feca623a1a3',
        oauthScope: [
            'openid', // default
            'profile', // default
            'email', // default
            'marketplace.read', // needed
            'geofence.read', // core business
            'geofence.write', // core business
            'location.read', // needed
            'map.read',
            'here-map.show-map.read', // replace with 'map.read',
            'here-map.proxy.read', // replace with 'map.read',
            'pois.read', // core business
            'pois.write', // core business
        ],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: 'https://47da8c28071d45a0a57ba7c97ce30f79@o117480.ingest.sentry.io/5797080',
    configCat: {
        apiKey: 'configcat-sdk-1/6IrcCH-KyECMpwsxp11Bow/2B2wzLYSo0Kfi42diUenmw',
    },
    hereBaseUrl: 'https://js.api.here.com/v3/3.1/',
    hereApiKey: import.meta.env.VITE_HERE_API_KEY,
};
