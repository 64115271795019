import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    arrayRemoveAll,
    autofill,
    Field,
    formValueSelector as formValueSelectorCreator,
    InjectedFormProps,
    reduxForm,
    WrappedFieldProps,
} from 'redux-form';
import maxBy from 'lodash/fp/maxBy';
import minBy from 'lodash/fp/minBy';

import ClearableInput from '../common/formInputs/ClearableInput';
import Checkbox from '../common/formInputs/Checkbox';
import Select from '../common/formInputs/Select';
import ShapeSelector from './ShapeSelector';
import { required } from '../../validation/required';
import SliderWithInput from '../common/formInputs/SliderWithInput';
import CircleFormAddressSearchContainer from './addressSearch/CircleFormAddressSearchContainer';
import PolygonFormAddressSearchContainer from './addressSearch/PolygonFormAddressSearchContainer';
import { toIntegerInBetween } from '../../normalization/toIntegerInBetween';
import { setMapBoundingBox, setMapZoom, undoPolygonPoint } from '../../actions/mapActions';
import { getGeofence as triggerFetchGeofence } from '../../actions/geofenceActions';
import { getGeofence as geofenceSelector } from '../../reducers/geofenceReducer';
import { getEditMode } from '../../reducers/selectors';
import { trackingHelper, Trigger } from '../../tracking/setupTracking';
import { Dispatch, State } from '../../../types';
import { Routing } from '../../app/Routing';
import { DEFAULT_ZOOM } from '../../reducers/mapReducer';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import {
    dataLayerPush,
    GAEventName,
    GAEventTrigger,
    GAFlowName,
} from '../../../configuration/googleAnalytics/googleAnalytics';
import { ConnectedOnboardingTip } from '../../onboarding/ConnectedOnboardingTip';
import { Tips } from '../../onboarding/types';
import { onboardingActions } from '../../onboarding/redux/oboarding.redux';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import useOnMount from '@rio-cloud/rio-uikit/hooks/useOnMount';
import { transformLocalToGlobalCoordinates } from '../../utils/coordinateTransformer';

export interface IGeofenceFormProps {
    onDeletePolygon: () => void;
    onUndoPolygonPoint: () => void;
    isPolygonal: boolean;
    isCircular: boolean;
    numberOfPointsDrawn: number;
    validShape?: boolean;
    geofenceId?: string;
    fetchGeofence: (geofenceId: string, navigate: NavigateFunction) => Promise<void>;
    setZoom: (zoom: number) => any;
    geofencePosition: { lat: number; lng: number };
    geofenceRadius: number;
    onCenterMap: (geofencePosition: { lat: number; lng: number }, geofenceRadius: number) => () => void;
}

const radiusMaxValue = 80000;
const radiusMinValue = 25;
const tracking = trackingHelper('geofencing, geofence');
const numberOfPointsDrawnForOnboardingHints = 4;

export const geofenceFormElements = {
    geofenceName: 'geofenceName',
    geofenceFormName: 'geofenceCreation',
    activateGeofence: 'activateGeofence',
    geofenceShape: 'geofenceShape',
    geofencePoints: 'points',
    geofenceSuggestionPoint: 'suggestionPoint',
    geofenceAddress: 'geofenceAddress',
    geofenceRadius: 'geofenceRadius',
    geofenceCategory: 'geofenceCategory',
    geofenceId: 'geofenceId',
};

export const geofenceCreationFormInitialValues = {
    [geofenceFormElements.geofencePoints]: [],
    [geofenceFormElements.geofenceRadius]: 500,
    [geofenceFormElements.geofenceShape]: 'circular',
    [geofenceFormElements.activateGeofence]: true,
};
const categoryOptions: Array<object> = [
    { id: 'CUSTOMER', label: <FormattedMessage id={'intl-msg:customer'} /> },
    { id: 'PARTNER', label: <FormattedMessage id={'intl-msg:partner'} /> },
    { id: 'MY_COMPANY', label: <FormattedMessage id={'intl-msg:my-company'} /> },
    { id: 'MISC', label: <FormattedMessage id={'intl-msg:my-misc-others'} /> },
];

const addressDisplay = (addressDisplayFormProps: WrappedFieldProps) => {
    return <input className={'form-control'} value={addressDisplayFormProps.input.value} disabled={true} />;
};

const CategoryInput = () => {
    return (
        <div className={'margin-bottom-20'}>
            <label>
                <FormattedMessage id={'intl-msg:category'} />
            </label>
            <Field
                name={geofenceFormElements.geofenceCategory}
                component={Select}
                options={categoryOptions}
                placeholderId={'categories'}
            />
        </div>
    );
};

const CircularFormOptions = ({
    isCircular,
    editMode,
    onCenterMap,
}: {
    isCircular: boolean;
    editMode: boolean;
    onCenterMap: () => void;
}) => {
    if (!isCircular) {
        return null;
    }

    const [radiusValid, setRadiusValid] = useState<boolean>(true);

    // Use a call back to avoid this. https://github.com/redux-form/redux-form/issues/4705
    const validateRadius = useCallback(() => {
        return radiusValid ? undefined : 'Required';
    }, [radiusValid]);

    const onCenter = useCallback(() => {
        onCenterMap();
    }, [onCenterMap]);

    const normalizeRadiusBetween: (value: number) => undefined | number | 0 = (value: number) => {
        return toIntegerInBetween(radiusMinValue, radiusMaxValue)(value);
    };

    const intl = useIntl();

    const centerMapButton = (
        <div className={'margin-left-10'}>
            <SimpleTooltip content={intl.formatMessage({ id: 'intl-msg:geofence.center-map' })} placement="top-start">
                <button
                    type="button"
                    className="btn btn-default btn-icon-only"
                    onClick={onCenter}
                    data-testid={'center-map-button'}
                >
                    <span className="rioglyph rioglyph-position" aria-hidden="true" />
                </button>
            </SimpleTooltip>
        </div>
    );

    return (
        <div>
            <div className={'margin-bottom-15'}>
                <label>
                    <FormattedMessage id={'intl-msg:address'} />
                </label>
                <Field
                    name={geofenceFormElements.geofenceAddress}
                    component={editMode ? addressDisplay : CircleFormAddressSearchContainer}
                />
            </div>

            <div className={'margin-bottom-15'}>
                <label>
                    <FormattedMessage id={'intl-msg:radius'} />
                </label>
                <Field
                    name={geofenceFormElements.geofenceRadius}
                    component={SliderWithInput}
                    children={!editMode ? centerMapButton : null}
                    maxValue={radiusMaxValue}
                    minValue={radiusMinValue}
                    setValid={setRadiusValid}
                    validate={validateRadius}
                    normalize={normalizeRadiusBetween}
                    disabled={editMode}
                />
            </div>
        </div>
    );
};

const PolygonalFormOptions = ({
    isPolygonal,
    editMode,
    validShape,
    numberOfPointsDrawn,
    onDeletePolygon,
    onUndoPolygonPoint,
}: {
    isPolygonal: boolean;
    editMode: boolean;
    validShape: boolean | undefined;
    numberOfPointsDrawn: number;
    onDeletePolygon: () => void;
    onUndoPolygonPoint: () => void;
}) => {
    const AddressSearchLabel = !editMode ? <FormattedMessage id={'intl-msg:centerMap'} /> : null;
    if (!isPolygonal) {
        return null;
    }

    return (
        <div>
            {isPolygonal && !editMode && (
                <div className={'margin-bottom-15'}>
                    <label>{AddressSearchLabel}</label>
                    <Field
                        name={geofenceFormElements.geofenceAddress}
                        component={editMode ? addressDisplay : PolygonFormAddressSearchContainer}
                    />
                </div>
            )}
            {editMode && (
                <div className={'alert alert-info margin-bottom-15'}>
                    <FormattedMessage id={'intl-msg:geofence.edit.text'} />
                </div>
            )}
            {!editMode && (
                <div className={'text-italic text-color-dark margin-top-15 margin-bottom-15'}>
                    <FormattedMessage id={'fleetmonitor.geofence.create.polygon.text'} />
                </div>
            )}
            {validShape !== undefined && !validShape && (
                <div
                    className={'alert alert-info margin-bottom-15'}
                    {...tracking.getTrackingDataLabels(Trigger.visibility, 'showSelfintersectionWarning')}
                >
                    <FormattedMessage id={'intl-msg:geofence.invalid.shape'} />
                </div>
            )}
            {!editMode && (
                <div className={'btn-group'}>
                    <ConnectedOnboardingTip
                        tipType={Tips.POLYGONAL_GEOFENCE_CREATION_UNDO_BUTTON}
                        titleTranslationId={'intl-msg:geofence.undo.last.point'}
                        contentTranslationId={'intl-msg:geofence.undo.last.point.onboarding.content'}
                        isEnabled={numberOfPointsDrawn >= numberOfPointsDrawnForOnboardingHints}
                    >
                        <SimpleTooltip
                            content={<FormattedMessage id={'intl-msg:geofence.undo.last.point'} />}
                            delay={{ show: 500, hide: 0 }}
                        >
                            <button
                                aria-label={'undo'}
                                className={'btn btn-default btn-icon-only'}
                                type={'button'}
                                onClick={onUndoPolygonPoint}
                                disabled={numberOfPointsDrawn === 0}
                            >
                                <span className={'rioglyph rioglyph-revert'} />
                            </button>
                        </SimpleTooltip>
                    </ConnectedOnboardingTip>
                    <button
                        className={'btn btn-default'}
                        name={'delete polygon'}
                        type={'button'}
                        onClick={onDeletePolygon}
                        disabled={numberOfPointsDrawn === 0}
                    >
                        <span className={'rioglyph rioglyph-trash'} />
                        <FormattedMessage id={'intl-msg:geofence.reset.points'} />
                    </button>
                </div>
            )}
        </div>
    );
};

const ShapeSelectorWrapper = ({ editMode }: { editMode: boolean }) => {
    if (editMode) {
        return null;
    }
    return (
        <div className={'margin-bottom-15'}>
            <label>
                <FormattedMessage id={'fleetmonitor.geofence.shape'} />
            </label>
            <ShapeSelector />
        </div>
    );
};

export const GeofenceForm = (props: IGeofenceFormProps & Partial<InjectedFormProps>) => {
    const {
        isPolygonal,
        isCircular,
        numberOfPointsDrawn,
        validShape,
        geofenceId,
        onDeletePolygon,
        onUndoPolygonPoint,
        fetchGeofence,
        handleSubmit,
        setZoom,
        geofencePosition,
        geofenceRadius,
        onCenterMap,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const editMode = !location.pathname.includes('create');

    const [geofenceFetched, setGeofenceFetched] = useState(false);

    useOnMount(() => {
        if (editMode && geofenceId) {
            fetchGeofence(geofenceId, navigate).then(() => {
                setGeofenceFetched(true);
            });
        } else {
            setZoom(DEFAULT_ZOOM);
        }
    }, []);

    return (
        (!editMode || geofenceFetched) && (
            <form onSubmit={handleSubmit}>
                <div className={'margin-bottom-15'}>
                    <label>
                        <FormattedMessage id={'fleetmonitor.name'} />
                    </label>
                    <Field
                        name={geofenceFormElements.geofenceName}
                        component={ClearableInput}
                        type="text"
                        placeholder="Name"
                        validate={required}
                    />
                </div>
                <CategoryInput />
                <div className={'margin-bottom-15'}>
                    <Field
                        name={geofenceFormElements.activateGeofence}
                        component={Checkbox}
                        checkboxLabel={<FormattedMessage id={'fleetmonitor.activateGeofence'} />}
                    />
                </div>
                <hr className={'border-width-1 text-color-light'} />
                <div className={'text-bold text-size-large margin-top-15 margin-bottom-15'}>
                    <FormattedMessage id={'intl-msg:geofence.settings'} />
                </div>
                <ShapeSelectorWrapper editMode={editMode} />
                <PolygonalFormOptions
                    isPolygonal={isPolygonal}
                    editMode={editMode}
                    validShape={validShape}
                    numberOfPointsDrawn={numberOfPointsDrawn}
                    onDeletePolygon={onDeletePolygon}
                    onUndoPolygonPoint={onUndoPolygonPoint}
                />
                <CircularFormOptions
                    isCircular={isCircular}
                    editMode={editMode}
                    onCenterMap={onCenterMap(geofencePosition, geofenceRadius)}
                />
            </form>
        )
    );
};

const mapStateToProps = (state: State) => {
    const formSelector = formValueSelectorCreator(geofenceFormElements.geofenceFormName);
    const shape = formSelector(state, geofenceFormElements.geofenceShape);
    const listOfAllPoints = formSelector(state, geofenceFormElements.geofencePoints);
    const radius = formSelector(state, geofenceFormElements.geofenceRadius);

    return {
        isPolygonal: shape === 'polygonal',
        isCircular: shape === 'circular',
        editMode: getEditMode(state) === 'UPDATE',
        numberOfPointsDrawn: listOfAllPoints?.length ?? 0,
        geofencePosition: listOfAllPoints?.[0],
        geofenceRadius: radius,
    };
};

const doFetchGeofence = (geofenceId: string) => (dispatch: Dispatch, getStore: () => State) => {
    return dispatch(triggerFetchGeofence(geofenceId)).then(() => {
        const geofence = geofenceSelector(getStore().geofence, geofenceId);
        const transformShape = (shape: string) =>
            shape === 'CIRCLE' ? 'circular' : shape === 'POLYGON' ? 'polygonal' : shape;
        if (geofence) {
            const formName = geofenceFormElements.geofenceFormName;
            dispatch(autofill(formName, geofenceFormElements.geofenceShape, transformShape(geofence.type)));
            dispatch(autofill(formName, geofenceFormElements.geofenceId, geofenceId));
            dispatch(autofill(formName, geofenceFormElements.geofenceName, geofence.name));
            dispatch(autofill(formName, geofenceFormElements.activateGeofence, geofence.active));
            dispatch(autofill(formName, geofenceFormElements.geofenceCategory, geofence.category));
            if (geofence.type === 'CIRCLE') {
                dispatch(autofill(formName, geofenceFormElements.geofenceRadius, geofence.radius));
                dispatch(autofill(formName, geofenceFormElements.geofenceAddress, geofence.center.address));
                dispatch(
                    autofill(formName, geofenceFormElements.geofencePoints, [
                        { lat: geofence.center.latitude, lng: geofence.center.longitude },
                    ])
                );
                centerMapOnGeofence(
                    {
                        lng: geofence.center.longitude,
                        lat: geofence.center.latitude,
                    },
                    geofence.radius,
                    dispatch
                );
            } else if (geofence.type === 'POLYGON') {
                if (geofence?.points && geofence.points.length > 0) {
                    const top = maxBy((it) => it.latitude, geofence.points)?.latitude!!;
                    const bottom = minBy((it) => it.latitude, geofence.points)?.latitude!!;
                    const right = maxBy((it) => it.longitude, geofence.points)?.longitude!!;
                    const left = minBy((it) => it.longitude, geofence.points)?.longitude!!;
                    dispatch(setMapBoundingBox({ top, bottom, left, right }));
                }
                dispatch(
                    autofill(
                        formName,
                        geofenceFormElements.geofencePoints,
                        geofence.points.map((point) => {
                            return { lat: point.latitude, lng: point.longitude };
                        })
                    )
                );
            }
        }
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchGeofence: (geofenceId: string, navigate: NavigateFunction) =>
            dispatch(doFetchGeofence(geofenceId)).catch(() => navigate(Routing.poiList)),
        onDeletePolygon: () => {
            dataLayerPush({
                trigger: GAEventTrigger.click,
                event: GAEventName.polygonalGeofenceMarkersReset,
                flow_name: GAFlowName.createGeofence,
            });
            dispatch(arrayRemoveAll(geofenceFormElements.geofenceFormName, geofenceFormElements.geofencePoints));
        },
        onUndoPolygonPoint: () => {
            dataLayerPush({
                trigger: GAEventTrigger.click,
                event: GAEventName.polygonalGeofenceLastMarkerRemoved,
                flow_name: GAFlowName.createGeofence,
            });
            // dispatch(arrayPop(geofenceFormElements.geofenceFormName, geofenceFormElements.geofencePoints));
            dispatch(undoPolygonPoint());
            dispatch(
                onboardingActions.setShowOnboardingTip({
                    type: Tips.POLYGONAL_GEOFENCE_CREATION_UNDO_BUTTON,
                    value: false,
                })
            );
        },
        setZoom: (zoom: number) => dispatch(setMapZoom(zoom)),
        onCenterMap: (geofencePosition: { lat: number; lng: number }, geofenceRadius: number) => () => {
            centerMapOnGeofence(geofencePosition, geofenceRadius, dispatch);
        },
    };
};

const centerMapOnGeofence = (
    geofencePosition: {
        lat: number;
        lng: number;
    },
    geofenceRadius: number,
    dispatch: Dispatch
) => {
    if (geofencePosition && geofenceRadius) {
        const boundingBox = calculateBoundingBoxForCircle(geofencePosition, 2 * geofenceRadius);
        dispatch(setMapBoundingBox(boundingBox));
    }
};

const calculateBoundingBoxForCircle = (center: { lat: number; lng: number }, radiusInMeters: number) => {
    const top = transformLocalToGlobalCoordinates({ x: 0, y: radiusInMeters }, center).lat;
    const bottom = transformLocalToGlobalCoordinates({ x: 0, y: -radiusInMeters }, center).lat;
    const right = transformLocalToGlobalCoordinates({ x: radiusInMeters, y: 0 }, center).lng;
    const left = transformLocalToGlobalCoordinates({ x: -radiusInMeters, y: 0 }, center).lng;
    return { top, bottom, left, right };
};

const ConnectedGeofenceForm = connect(mapStateToProps, mapDispatchToProps)(GeofenceForm);

export default reduxForm<{}, { geofenceId?: string; validShape?: boolean }>({
    form: geofenceFormElements.geofenceFormName,
    initialValues: geofenceCreationFormInitialValues,
})(ConnectedGeofenceForm as any);
