import { fetchGeoBookedFailed, fetchGeoBookedStarted, storeGeoBooked } from '../actions/bookingsActions';
interface IBookingsState {
    geoBooked: boolean;
    isLoading: boolean;
}

const INITIAL_STATE: IBookingsState = {
    geoBooked: false,
    isLoading: false,
};

export type handledActions =
    | ReturnType<typeof fetchGeoBookedStarted>
    | ReturnType<typeof fetchGeoBookedFailed>
    | ReturnType<typeof storeGeoBooked>;

export const bookingsReducer = (state: IBookingsState = INITIAL_STATE, action: handledActions) => {
    switch (action.type) {
        case 'FETCH_GEO_BOOKED_STARTED':
            return { ...state, isLoading: true };
        case 'FETCH_GEO_BOOKED_FAILED':
            return { ...state, isLoading: false, geoBooked: true };
        case 'STORE_GEO_BOOKED':
            return { ...state, isLoading: false, geoBooked: action.payload };
        default:
            return state;
    }
};

export const hasGeoBooking = (state: IBookingsState) => state.geoBooked;
export const isLoading = (state: IBookingsState) => state.isLoading;

export default bookingsReducer;
