import { toInteger } from './toInteger';

export const toIntegerInBetween = (min: number | undefined, max: number | undefined) => {
    if (min && max && min >= max) {
        throw Error('Minimum value has to be smaller equal than maximum value');
    }
    return (value: number | undefined) => {
        const normalizedValue = toInteger(value);

        if (normalizedValue === undefined) {
            return normalizedValue;
        }

        if (max !== undefined && normalizedValue > max) {
            return max;
        }

        if (min !== undefined && normalizedValue < min) {
            return min;
        }

        return normalizedValue;
    };
};
