import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { WrappedFieldProps } from 'redux-form';
import { default as SelectUiKit } from '@rio-cloud/rio-uikit/Select';

export interface IProps {
    options: Array<object>;
    placeholderId: string;
}

class Select extends React.Component<WrappedFieldProps & IProps> {
    render() {
        const {
            input: { onChange, value },
            placeholderId,
            options,
        } = this.props;

        const onChangeHandler = (selectedItem: any) => onChange(selectedItem.id);
        return (
            <SelectUiKit
                onChange={onChangeHandler}
                options={options}
                placeholder={<FormattedMessage id={'intl-msg:' + placeholderId} />}
                value={Array.isArray(value) ? value : [value]}
            />
        );
    }
}

export default Select;
