import { FormattedMessage } from 'react-intl';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import IframeResizer from 'iframe-resizer-react';

import { config } from '../../config';
import { HeaderPropertiesFromDispatch, HeaderPropertiesFromState } from './Header.container';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';

type HeaderProperties = HeaderPropertiesFromDispatch & HeaderPropertiesFromState;

const Header = (props: HeaderProperties) => {
    const { homeRoute, showPositionSharingDialog } = props;

    const navItems = [
        {
            key: 'map',
            route: (
                <a href={'https://livemonitor.rio.cloud/#map'}>
                    <FormattedMessage id={'fleetmonitor.subModuleName.monitor'} />
                </a>
            ),
        },
        {
            key: 'pois',
            route: (
                <a href={'/#pois'}>
                    <FormattedMessage id={'fleetmonitor.subModuleName.poi'} />
                </a>
            ),
        },
        {
            key: 'routeHistory',
            route: (
                <a href={'https://asset-history.rio.cloud/'}>
                    <FormattedMessage id={'fleetmonitor.subModuleName.eventHistory'} />
                </a>
            ),
        },
        {
            key: 'positionSharing',
            route: (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a>
                    <div onClick={showPositionSharingDialog}>
                        <FormattedMessage id={'intl-msg:glossary.positionSharing'} />
                    </div>
                </a>
            ),
        },
    ];

    const environment = import.meta.env.PROD ? 'production' : 'local';

    const notifications = <RioNotifications />;
    const userMenu = <DefaultUserMenu environment={environment} />;
    const menuUrl = config.backend.MENU_SERVICE as string;
    const appNavigator = <IframeResizer className={'iFrameResizer'} src={menuUrl} />;

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    const homeLink = <a href={homeRoute} />;

    return (
        <ApplicationHeader
            label={<FormattedMessage id="intl-msg:moduleName" />}
            appNavigator={appNavigator}
            homeRoute={homeLink}
            navItems={navItems}
            actionBarItems={[notifications, userMenu]}
        />
    );
};

export default Header;
