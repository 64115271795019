import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Dialog from '@rio-cloud/rio-uikit/Dialog';

import { State } from '../../reducers';
import { Dispatch } from 'redux';
import { FunctionProperties, NonFunctionProperties } from '../utils/typescriptutils/typeManipulation';
import { positionSharingActions } from './redux/positionSharing.redux';
import { getShowPositionSharingDialog } from './redux/positionSharing.selectors';

const ShareLinkDialog = (props: ShareLinkDialogProps) => {
    const { showShareLinkDialog, onHide } = props;

    const body = (
        <div className={'iframe-wrapper'}>
            <iframe
                title={'Position sharing Widget'}
                className={'unstyled bg-lighter full-window'}
                src={'https://position-sharing.rio.cloud/'}
            />
        </div>
    );

    return (
        <Dialog
            show={showShareLinkDialog}
            title={<FormattedMessage id={'intl-msg:glossary.positionSharing'} />}
            bodyClassName={'padding-0 display-flex'}
            body={body}
            onClose={onHide}
            showCloseButton={true}
            bsSize={Dialog.SIZE_FULL_SCREEN}
        />
    );
};

interface ShareLinkDialogProps {
    showShareLinkDialog: boolean;
    onHide: () => void;
}

const mapStateToProps = (state: State): NonFunctionProperties<ShareLinkDialogProps> => ({
    showShareLinkDialog: getShowPositionSharingDialog(state),
});

const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<ShareLinkDialogProps> => ({
    onHide: () => dispatch(positionSharingActions.setShowPositionSharingDialog(false)),
});

export const PositionSharingDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ShareLinkDialog);
