export const getAddressFromQueryString = (search: string | undefined) => {
    if (search) {
        const params = new URLSearchParams(search);
        const latitude = parseFloat(params.get('lat') as string);
        const longitude = parseFloat(params.get('lng') as string);
        const label = params.get('label') as string;

        if (isNaN(latitude) || isNaN(longitude)) {
            return;
        } else {
            return {
                position: {
                    latitude,
                    longitude,
                },
                label: label ? label : `${latitude}, ${longitude}`,
            };
        }
    }
};
