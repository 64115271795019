import fetchJSON, { fetchResponse, getFetchOptions } from '../utils/fetchJSON';
import { AuthorizationError } from '../utils/authorizationError';
import { Dispatch, State } from '../../types';
import { getAccessToken } from '../../configuration';

export const authenticatedGet = (url: string) => (dispatch: Dispatch, getStore: () => State) => {
    const token = getAccessToken(getStore());
    const options = getFetchOptions(token);

    return fetchJSON(url, options).catch((err) => {
        if (err instanceof AuthorizationError) {
            return Promise.reject(err);
        }
        return Promise.reject(err);
    });
};

export const authenticatedPost = (url: string, body: {}) => (dispatch: Dispatch, getStore: () => State) => {
    const token = getAccessToken(getStore());
    const options = getFetchOptions(token, 'POST') as RequestInit;
    options.body = JSON.stringify(body);

    return fetchJSON(url, options).catch((err) => {
        if (err instanceof AuthorizationError) {
            return Promise.reject(err);
        }
        return Promise.reject(err);
    });
};

export const authenticatedPut = (url: string, body: {}) => (dispatch: Dispatch, getStore: () => State) => {
    const token = getAccessToken(getStore());
    const options = getFetchOptions(token, 'PUT') as RequestInit;
    options.body = JSON.stringify(body);

    return fetchResponse(url, options).catch((err) => {
        if (err instanceof AuthorizationError) {
            return Promise.reject(err);
        }
        return Promise.reject(err);
    });
};

export const authenticatedDelete = (url: string) => (dispatch: Dispatch, getStore: () => State) => {
    const token = getAccessToken(getStore());
    const options = getFetchOptions(token, 'DELETE') as RequestInit;

    return fetchResponse(url, options)
        .then(() => Promise.resolve())
        .catch((err) => {
            if (err instanceof AuthorizationError) {
                return Promise.reject(err);
            }
            return Promise.reject(err);
        });
};

export const authenticatedPatch = (url: string, body: {}) => (dispatch: Dispatch, getStore: () => State) => {
    const token = getAccessToken(getStore());
    const options = getFetchOptions(token, 'PATCH') as RequestInit;
    options.body = JSON.stringify(body);

    return fetchJSON(url, options).catch((err) => {
        if (err instanceof AuthorizationError) {
            return Promise.reject(err);
        }
        return Promise.reject(err);
    });
};
