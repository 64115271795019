import * as React from 'react';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

export type NotificationLevel = 'info' | 'success' | 'error' | 'warning';

type MessageValue = string | number | boolean | Date | null | undefined;

interface ValueType {
    [key: string]: MessageValue | JSX.Element;
}

export const showNotification = (messageId: string, level: NotificationLevel, values?: ValueType) => () => {
    Notification[level](<FormattedMessage id={messageId} values={values} />);
};
