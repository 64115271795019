import React from 'react';
import { connect } from 'react-redux';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import compact from 'lodash/fp/compact';
import { deletePOIOrGeofence } from '../../actions/legacyActions';
import { hideDeletePOIDialog } from '../../actions/poiActions';
import { getDeleteGeofence, getDeletePoi, getDeletePoiGeofenceId } from '../../reducers/selectors';
import { Routing } from '../../app/Routing';
import { Dispatch, State } from '../../../types';
import './dialog.less';
import { POI } from '../../reducers/poiReducer';
import { Geofence } from '../../reducers/geofenceReducer';
import { FunctionProperties, NonFunctionProperties } from '../../utils/typescriptutils/typeManipulation';
import { useIntl } from 'react-intl';
import { NavigateFunction, useNavigate } from 'react-router';

interface ConfirmationDialogProps {
    idToDelete: string | undefined;
    poiToDelete: POI | undefined;
    geofenceToDelete: Geofence | undefined;
    deletePOI: (id: string, navigate: NavigateFunction) => void;
    hideDialog: () => void;
}

const ConfirmationDialogWrapper = (props: ConfirmationDialogProps) => {
    const { deletePOI, poiToDelete, idToDelete, geofenceToDelete, hideDialog } = props;
    const intl = useIntl();
    const navigate = useNavigate();

    const confirmPOIDeletion = (): void => {
        if (idToDelete) {
            deletePOI(idToDelete, navigate);
        }
        hideDialog();
    };

    const title = compact([
        intl.formatMessage({ id: 'intl-msg:delete' }),
        poiToDelete?.name ?? `"${geofenceToDelete?.name}"`,
    ]).join(' - ');

    const deleteConfirmationProps = {
        show: !!idToDelete,
        title,
        content: (
            <div>
                <div className={'margin-bottom-25'}>
                    {intl.formatMessage({ id: 'confirmation.delete.poi.or.geofence.dialog.content' })}
                </div>
                <div className={'alert alert-info margin-bottom-0'}>
                    {intl.formatMessage({ id: 'fleetmonitor.deletePOI.dialog.content.hint' })}
                </div>
            </div>
        ),
        onClickConfirm: () => confirmPOIDeletion(),
        onClickCancel: () => props.hideDialog(),
        confirmButtonText: intl.formatMessage({ id: 'intl-msg:delete' }),
        cancelButtonText: intl.formatMessage({ id: 'fleetmonitor.abort' }),
    };

    return <ConfirmationDialog {...deleteConfirmationProps} bsSize={'sm'} useOverflow={false} />;
};

const mapStateToProps = (state: State): NonFunctionProperties<ConfirmationDialogProps> => {
    return {
        idToDelete: getDeletePoiGeofenceId(state),
        poiToDelete: getDeletePoi(state),
        geofenceToDelete: getDeleteGeofence(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<ConfirmationDialogProps> => ({
    deletePOI: (id: string, navigate: NavigateFunction) =>
        dispatch(deletePOIOrGeofence(id)).then(() => navigate(Routing.poiList)),
    hideDialog: () => dispatch(hideDeletePOIDialog()),
});

export const ConfirmationDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialogWrapper);
