import { Coordinates } from '../types';
import { testSelfIntersection } from './isPolygonSelfIntersecting';

interface Vector {
    x: number;
    y: number;
}

const diff = (v: Vector, w: Vector) => ({ x: v.x - w.x, y: v.y - w.y });

const sum = (v: Vector, w: Vector) => ({ x: v.x + w.x, y: v.y + w.y });

const dot = (v: Vector, w: Vector) => v.x * w.x + v.y * w.y;

const mul = (v: Vector, s: number) => ({ x: v.x * s, y: v.y * s });

const abs = (v: Vector) => Math.sqrt(v.x * v.x + v.y * v.y);

const NUMERICAL_TOLERANCE = 1e-10;

const nextIndex = (index: number, length: number) => (index + 1) % length;

const equalsWithinTolerance = (num1: number, num2: number) => {
    return Math.abs(num1 - num2) < NUMERICAL_TOLERANCE;
};

const greaterThanWithinTolerance = (num1: number, num2: number) => {
    return num1 > num2 && !equalsWithinTolerance(num1, num2);
};

interface DistanceMetric {
    // Distance from the point to the closest point on the segment
    distancePointToSegment: number;
    // If the projection of the point onto the segment lies outside the segment,
    // this is the distance of the projection to the closest endpoint, otherwise it is 0
    projectedDistance: number;

    // potential insertion index (index of second point of the segment)
    index: number;
}

const distance = (
    newPoint: Vector,
    firstPointOfSegment: Vector,
    secondPointOfSegment: Vector,
    indexOfSecondPoint: number
): DistanceMetric => {
    const segmentLength = abs(diff(secondPointOfSegment, firstPointOfSegment));
    if (equalsWithinTolerance(segmentLength, 0)) {
        const distancePointToSegment = abs(diff(newPoint, firstPointOfSegment));
        return { distancePointToSegment, projectedDistance: distancePointToSegment, index: indexOfSecondPoint };
    }
    const projectedLength =
        dot(diff(newPoint, firstPointOfSegment), diff(secondPointOfSegment, firstPointOfSegment)) / segmentLength;
    const normalizedProjectedLength = projectedLength / segmentLength;
    let projectedDistance = 0;
    let normalizedProjectedLengthWithinSegment = normalizedProjectedLength;
    if (normalizedProjectedLength < 0) {
        projectedDistance = -projectedLength;
        normalizedProjectedLengthWithinSegment = 0;
    } else if (normalizedProjectedLength > 1) {
        projectedDistance = projectedLength - segmentLength;
        normalizedProjectedLengthWithinSegment = 1;
    }
    const projection = sum(
        firstPointOfSegment,
        mul(diff(secondPointOfSegment, firstPointOfSegment), normalizedProjectedLengthWithinSegment)
    );
    const distancePointToSegment = abs(diff(newPoint, projection));
    return { distancePointToSegment, projectedDistance, index: indexOfSecondPoint };
};

const calculateAllDistances = (points: Array<Vector>, newPoint: Vector) => {
    return points.map((point, index) => {
        const indexOfNextPoint = nextIndex(index, points.length);
        const nextPoint = points[indexOfNextPoint];
        return distance(newPoint, point, nextPoint, indexOfNextPoint);
    });
};

const compareDistanceMetrics = (a: DistanceMetric, b: DistanceMetric): number => {
    if (
        greaterThanWithinTolerance(a.distancePointToSegment, b.distancePointToSegment) ||
        (equalsWithinTolerance(a.distancePointToSegment, b.distancePointToSegment) &&
            b.projectedDistance < a.projectedDistance)
    ) {
        return 1;
    }
    return -1;
};

const isInsertValid = (points: Array<Vector>, newPoint: Vector, insertIndex: number) => {
    // Don't check for self-intersection if the existing polygon is already self-intersecting
    if (testSelfIntersection(points)) return true;
    // Insert new point and check for self-intersection of new polygon
    const newPoints = points.toSpliced(insertIndex, 0, newPoint);
    return !testSelfIntersection(newPoints);
};

/**
 * This function finds the closest segment to the new point and returns the index that would insert the new point into that
 * segment in the polygonal geofence.
 *
 * For the new point, we calculate the following for each segment (or edge) of the polygonal geofence:
 *
 * distancePointToSegment: the shortest distance from a given point to the closest point on a line segment.
 * projectedDistance: the distance of the projection of the point onto the segment to the closest endpoint of the segment.
 *
 * A projection is a line drawn perpendicular from the point to the line segment.
 * The point where this perpendicular line intersects the segment is the projection of the new point onto the segment.
 * If the projection falls within the line segment , then projectedDistance is zero. If it falls outside
 * then projectedDistance is the distance of the projection to the closest endpoint.
 *
 * We then sort the segments by distancePointToSegment and projectedDistance and then find the first segment where
 * the new point can be inserted without self-intersection, this give us our insertion index.
 *
 * @param newCoordinates - the new point to be inserted
 * @param coordinates - the points of the existing polygonal geofence
 */
export const calculateNewIndex = (newCoordinates: Coordinates, coordinates: Array<Coordinates>) => {
    if (!coordinates || coordinates.length <= 2) {
        return coordinates ? coordinates.length : 0;
    }

    const points: Array<Vector> = coordinates.map((coordinate) => ({ x: coordinate.lng, y: coordinate.lat }));
    const newPoint: Vector = { x: newCoordinates.lng, y: newCoordinates.lat };

    const distances = calculateAllDistances(points, newPoint);
    const sortedDistances = [...distances].sort(compareDistanceMetrics);

    const closestDistance =
        sortedDistances.find((distance) => {
            return isInsertValid(points, newPoint, distance.index);
        }) ?? sortedDistances[0];

    return closestDistance.index;
};
