import * as React from 'react';
import {useState} from 'react';
import {WrappedFieldProps} from 'redux-form';
import {default as SliderUiKit} from '@rio-cloud/rio-uikit/Slider';
import {parseInt} from 'lodash';
import {FormattedMessage} from 'react-intl';

export interface IProps {
    children?: React.ReactNode;
    maxValue: number;
    minValue: number;
    disabled?: boolean;
    setValid: (valid: boolean) => void;
}

const SliderWithInput = (props: WrappedFieldProps & IProps) => {
    const {
        input: { value, onChange },
        meta: { valid },
        children,
        maxValue,
        minValue,
        setValid,
        disabled,
    } = props;

    // estimate number of pixels in our slider bar.
    const sliderMaxValue = 500;
    const sliderMinValue = 1;

    const mapSliderToExternalValue = (value: number) => {
        let normalizedValue = 1;
        if (value < sliderMaxValue) {
            normalizedValue = (value - sliderMinValue) / (sliderMaxValue - sliderMinValue);
        }

        return Math.round(minValue * Math.exp(Math.log(maxValue / minValue) * normalizedValue));
    };

    const mapExternalToSliderValue = (value: number) => {
        const normalizedValue = Math.log(value / minValue) / Math.log(maxValue / minValue);
        return Math.round(normalizedValue * (sliderMaxValue - sliderMinValue) + sliderMinValue);
    };

    const blockInvalidChars = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const isCtrlKey = [
            'Control',
            'Shift',
            'End',
            'Home',
            'Backspace',
            'Delete',
            'ArrowLeft',
            'ArrowRight',
            'ArrowUp',
            'ArrowDown',
        ].includes(event.key);
        if ((event.key >= '0' && event.key <= '9') || isCtrlKey) {
            return;
        } else {
            event.preventDefault();
        }
        return;
    };

    const initialSliderValue = mapExternalToSliderValue(value);
    const [sliderValue, setSliderValue] = useState<number>(initialSliderValue);
    const [inputValue, setInputValue] = useState<number | string>(value);

    const showError = !valid;

    const handleInputChange = (event: { target: { value: string } }) => {
        const inputValue = parseInt(event.target.value, 10);
        if (!isNaN(inputValue)) {
            setInputValue(inputValue);
            const boundedValue = Math.min(Math.max(inputValue, minValue), maxValue);
            setValid(boundedValue === inputValue);
            onChange(boundedValue);
            setSliderValue(mapExternalToSliderValue(boundedValue));
        } else {
            setValid(false);
            setInputValue('');
            setSliderValue(sliderMinValue);
        }
    };

    const handleSliderChange = (value: number) => {
        const externalValue = mapSliderToExternalValue(value);
        setInputValue(externalValue);
        onChange(externalValue);
        setSliderValue(value);
        setValid(true);
    };


    const radiusErrorIntlValues = {
        minValue,
        maxValue
    }

    return (
        <div
            className={`form-group display-flex flex-column form-control-feedback-wrapper ${showError ? 'has-feedback has-error' : ''}`}
        >
            <div className={'display-flex align-items-center'}>
                <SliderUiKit
                    value={sliderValue}
                    onChange={handleSliderChange}
                    maxValue={sliderMaxValue}
                    minValue={sliderMinValue}
                    disabled={disabled}
                />
                <div className={'width-100 margin-left-10'}>
                    <input
                        className={'form-control text-center padding-5 no-controls'}
                        type={'number'}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={blockInvalidChars}
                        disabled={disabled}
                    />
                </div>
                {children}
            </div>


            {showError && (
                <div className={'display-flex justify-content-end align-items-end height-30'}>
                    <span className={'help-block'}>
                        <FormattedMessage id={'intl-msg:geofence.radius.error'} values={radiusErrorIntlValues}/>
                    </span>
                </div>
            )}
        </div>
    );
};

export default SliderWithInput;
