import { FormattedMessage } from 'react-intl';
import NotBookedState from '@rio-cloud/rio-uikit/NotBookedState';

const BUY_BUTTON_LINK = 'https://buybutton.marketplace.rio.cloud/?productId=rio-sku00000047&level=10';

export const NoProductBooking = () => (
    <NotBookedState
        headline={<FormattedMessage id={'intl-msg:MissingProduct.heading'} />}
        message={<FormattedMessage id={'intl-msg:MissingProduct.description'} />}
    >
        <iframe title={'buyButton'} className={'buyButton border-none width-100pct height-40'} src={BUY_BUTTON_LINK} />
    </NotBookedState>
);
