import {createSlice} from '@reduxjs/toolkit';
import {loadIsEnabledBottomSheetState, saveIsEnabledBottomSheetState} from './geofenceToLocalStorage';


export const geofenceBottomSheet = 'geofenceBottomSheet';


export interface GeofenceBottomSheetState {
    showGeofenceBottomSheet: boolean;
    isEnabled: boolean;
}

const initialState: GeofenceBottomSheetState = {
    showGeofenceBottomSheet: false,
    isEnabled:  loadIsEnabledBottomSheetState(),
};

const { reducer, actions } = createSlice({
    name: geofenceBottomSheet,
    initialState,
    reducers: {

        showGeofenceBottomSheet: (state: GeofenceBottomSheetState): void => {
            if(state.isEnabled) {
                state.showGeofenceBottomSheet = true;
            }
        },

        hideGeofenceBottomSheet: (state: GeofenceBottomSheetState): void => {
            state.showGeofenceBottomSheet = false
            state.isEnabled = false
            saveIsEnabledBottomSheetState(false);
        },
    },
});

export const geofenceBottomSheetActions = actions;
export const geofenceBottomSheetReducer = reducer;