import { ReactElement, Suspense } from 'react';
import { useHereMap } from '../../configuration/setup/hooks/useHereMap';

type MapLoaderProps = {
    children: ReactElement;
    fallback?: ReactElement | null;
};

const MapLoader = ({ children, fallback = null }: MapLoaderProps) => {
    const { isLoading, error, hasLoaded } = useHereMap();

    if (!hasLoaded) {
        return fallback;
    }

    return <Suspense fallback={fallback}>{children}</Suspense>;
};

export default MapLoader;
