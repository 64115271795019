declare global {
    interface Window {
        dataLayer?: Array<any>;
    }
}

export enum GAEventTrigger {
    click = 'click',
    visibility = 'visibility',
}

export enum GAFlowName {
    createGeofence = 'create_geofence',
}

export enum GAElementName {}

export enum GAEventName {
    createGeofenceClicked = 'create_geofence_clicked',
    polygonalGeofenceMarkerDragged = 'polygonal_geofence_marker_dragged',
    polygonalGeofenceMarkersReset = 'polygonal_geofence_markers_reset',
    polygonalGeofenceLastMarkerRemoved = 'polygonal_geofence_last_marker_removed',
    circularGeofenceCreated = 'circular_geofence_created',
    polygonalGeofenceCreated = 'polygonal_geofence_created',
}

interface UserProperties {
    accountId?: string;
    tenant?: string;
}

interface UserEvent {
    trigger: GAEventTrigger;
    event: GAEventName;
    element_name?: GAElementName;
    flow_name?: GAFlowName;
    element_state?: string;
}

export const dataLayerPush = (event: UserEvent | UserProperties) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ...event });
};
