import React, { useEffect, useState } from 'react';

import { IEvent } from './AddressSearch';
import { Coordinates } from '../../../types';
import { AutoSuggest, AutoSuggestSuggestion, SelectedSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';
import { IntlShape } from 'react-intl';
import useAfterMount from '@rio-cloud/rio-uikit/hooks/useAfterMount';
import { useGetAutosuggestionQuery } from './hereAutoSuggestApi';
import { isEqual } from 'lodash';

const INPUT_ID = 'poi-dialog-autosuggest-input-id';

export interface HerePlace {
    title: string;
    address: {
        label: string;
    };
    position: Coordinates | undefined;
}

interface SuggestAddressProps {
    language: string;
    maxResults: number;
    placeholder: string;
    onChange: (event: IEvent | null) => void;
    address?: {
        label?: string;
        position?: Coordinates;
    };
    currentMapCenter?: {
        lng: number;
        lat: number;
    };
    icon: string;
    hereApiKey: string;
    intl: IntlShape;
}

const getAddressLabel = (props: SuggestAddressProps): string => props.address?.label || '';

const COORDS_REGEX = /^\s*(-?\d+(\.\d+)?)\s*[,\s]\s*(-?\d+(\.\d+)?)\s*$/;

const parsePositionFromString = (value: string): Coordinates | undefined => {
    const match = COORDS_REGEX.exec(value);

    if (match) {
        const lat = parseFloat(match[1]);
        const lng = parseFloat(match[3]);

        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
            return {
                lat,
                lng,
            };
        }
    }
};

export const SuggestAddress = (props: SuggestAddressProps) => {
    const { icon, intl, placeholder, onChange, address } = props;
    const [suggestions, setSuggestions] = useState<Array<AutoSuggestSuggestion>>([]);
    const [addressLabel, setAddressLabel] = useState<string>(getAddressLabel(props));
    const [addressCopy, setAddressCopy] = useState(address);

    useEffect(() => {
        setAddressCopy(address);
    }, [address]);

    useAfterMount(() => {
        if (!isEqual(addressCopy, address) && addressLabel !== getAddressLabel(props)) {
            setAddressLabel(getAddressLabel(props));
            setAddressCopy(address);

            const input = document.getElementById(INPUT_ID);

            if (input) {
                setTimeout(() => input.focus(), 0);
            }
        }
    }, [address, addressLabel]);

    const { data: autoSuggestionData, isLoading: isLoadingAutoSuggest } = useGetAutosuggestionQuery(
        {
            value: addressLabel,
            language: props.language,
            maxResults: props.maxResults,
            hereApiKey: props.hereApiKey,
            currentMapCenter: props.currentMapCenter,
        },
        { skip: !addressLabel }
    );

    useEffect(() => {
        const coords = parsePositionFromString(addressLabel);
        if (coords) {
            const label = `${coords.lat}, ${coords.lng}`;
            setSuggestions([{ title: label, label, latitude: coords.lat, longitude: coords.lng }]);
        } else if (autoSuggestionData && addressLabel) {
            setSuggestions(autoSuggestionData);
        }
    }, [autoSuggestionData, addressLabel]);

    const onClear = () => {
        onChange(null);
        setSuggestions([]);
    };

    const onBlur = () => {
        if (getAddressLabel(props) && getAddressLabel(props) !== addressLabel) {
            setAddressLabel(`${addressLabel} `);
        }
    };

    const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
        setAddressLabel(value);
        setSuggestions([]);
        if (!value) {
            return;
        }
    };

    const getSuggestionValue = (suggestion: AutoSuggestSuggestion) => {
        return suggestion.label || '';
    };

    const onSuggestionSelected = (_: unknown, selectedSuggestion: SelectedSuggestion) => {
        onChange(selectedSuggestion.suggestion as IEvent);
    };

    const inputProps = {
        placeholder,
        value: addressLabel,
        onClear,
        onBlur,
        icon,
    };

    return (
        <div className={'suggestAddress'}>
            <AutoSuggest
                suggestions={suggestions}
                getSuggestionValue={getSuggestionValue}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionSelected={onSuggestionSelected}
                isLoading={isLoadingAutoSuggest}
                inputProps={inputProps}
                noItemMessage={intl.formatMessage({ id: 'fleetmonitor.select.valid.address' })}
            />
        </div>
    );
};
