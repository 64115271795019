import { autofill, getFormValues } from 'redux-form';
import { poiFormElements, POIFormValues } from '../components/poi/PoiForm';
import { setMapCenter } from './mapActions';
import { authenticatedDelete, authenticatedGet, authenticatedPut } from './fetchActions';
import { NotFoundError } from '../utils/notFoundError';
import { showNotification } from './notificationActions';
import { mapFormValuesToCustomerPOIDto, mapFromPOIDtoToPOI } from '../mapper/PoiMapper';
import { POI } from '../reducers/poiReducer';
import { Dispatch, State } from '../../types';
import { config } from '../../config';

const poiServicePath = () => config.backend.RIO_POISERVICE + '/pois/';

export const putPOI = () => (dispatch: Dispatch, getStore: () => State) => {
    const poi = mapFormValuesToCustomerPOIDto(getFormValues(poiFormElements.formName)(getStore()) as POIFormValues);
    const path = poiServicePath() + `${poi.id}`;
    return dispatch(authenticatedPut(path, poi));
};

export const deletePOI = (poiId: string) => (dispatch: Dispatch) => {
    const path = poiServicePath() + `${poiId}`;
    return dispatch(authenticatedDelete(path))
        .then(() => dispatch(poiDeleted(poiId)))
        .then(() => dispatch(showNotification('fleetmonitor.poi.successfullyDeleted', 'success')));
};

export const poiDeleted = (poiId: string) => ({
    type: 'POI_DELETED' as 'POI_DELETED',
    payload: { id: poiId },
});

export const fetchPOI = (poiId: string) => (dispatch: Dispatch) => {
    const path = poiServicePath() + `${poiId}`;

    return dispatch(authenticatedGet(path))
        .then((externalPoi) => {
            const poi = mapFromPOIDtoToPOI(externalPoi);
            dispatch(poiFetched(poi));
            return poi;
        })
        .catch((error) => {
            if (error instanceof NotFoundError) {
                dispatch(showNotification('intl-msg:poi.error.notfound.message', 'error', { id: poiId }));
            } else {
                dispatch(showNotification('intl-msg:error.general.message', 'error'));
            }
            return Promise.reject(error);
        });
};

export const fetchPOIs = () => (dispatch: Dispatch) => {
    const path = config.backend.RIO_POISERVICE + '/pois?type=CUSTOMER';

    return dispatch(authenticatedGet(path))
        .then((externalPOIs) => {
            const mappedPOIs = externalPOIs.items.map((externalPOI: any) => mapFromPOIDtoToPOI(externalPOI));
            dispatch(poisFetched(mappedPOIs));
        })
        .catch((error) => {
            dispatch(showNotification('intl-msg:error.general.message', 'error'));
            return Promise.reject(error);
        });
};

export const poisFetched = (pois: Array<POI>) => ({
    type: 'POIS_FETCHED' as 'POIS_FETCHED',
    payload: pois,
});

export const poiFetched = (poi: POI) => ({
    type: 'POI_FETCHED' as 'POI_FETCHED',
    payload: poi,
});

export const fetchPOIAndAutofillForm = (poiId: string) => (dispatch: Dispatch) => {
    return dispatch(fetchPOI(poiId)).then((poi) => {
        dispatch(autofill(poiFormElements.formName, poiFormElements.id, poi.id));
        dispatch(autofill(poiFormElements.formName, poiFormElements.poiName, poi.name));
        dispatch(
            autofill(poiFormElements.formName, poiFormElements.address, {
                label: poi.address,
                position: { lat: poi.coordinates.latitude, lng: poi.coordinates.longitude },
            })
        );
        dispatch(autofill(poiFormElements.formName, poiFormElements.category, poi.category));
        dispatch(setMapCenter({ latitude: poi.coordinates.latitude, longitude: poi.coordinates.longitude }));
    });
};

export const showDeletePOIDialog = (poiId: string) => ({
    type: 'SHOW_DELETE_POI_DIALOG' as 'SHOW_DELETE_POI_DIALOG',
    payload: poiId,
});
export const hideDeletePOIDialog = () => ({
    type: 'HIDE_DELETE_POI_DIALOG' as 'HIDE_DELETE_POI_DIALOG',
});
