import { setMapBoundingBox, setMapCenter, setMapZoom } from '../actions/mapActions';

export interface BoundingBox {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export interface MapState {
    zoom?: number;
    mapCenter?: {
        longitude: number;
        latitude: number;
    };
    boundingBox?: BoundingBox;
    style: string;
}

export const DEFAULT_ZOOM = 13;

const INITIALE_STATE = {
    zoom: DEFAULT_ZOOM,
    // italian style variable
    mapCenter: {
        // berlin
        longitude: 13.4,
        latitude: 52.5,
    },
    style: 'TODO Do something about me',
};

export type handledActions =
    | ReturnType<typeof setMapCenter>
    | ReturnType<typeof setMapZoom>
    | ReturnType<typeof setMapBoundingBox>;

export const mapReducer = (state: MapState = INITIALE_STATE, action: handledActions): MapState => {
    switch (action.type) {
        case 'ACTION_SET_MAP_CENTER':
            return { ...state, mapCenter: action.payload, boundingBox: undefined };
        case 'ACTION_SET_MAP_ZOOM':
            return { ...state, zoom: action.payload, boundingBox: undefined };
        case 'ACTION_SET_MAP_BOUNDING_BOX':
            return { ...state, zoom: undefined, mapCenter: undefined, boundingBox: action.payload };
        default:
            return state;
    }
};

export const getMapCenter = (state: MapState) => {
    if (state.mapCenter) {
        return {
            lng: state.mapCenter?.longitude,
            lat: state.mapCenter?.latitude,
        };
    }
    return undefined;
};

export const getZoom = (state: MapState) => state.zoom;
export const getMapStyle = (state: MapState) => state.style;
export const getMapBoundingBox = (state: MapState) => state.boundingBox;

export default mapReducer;
