import * as React from 'react';
import { FormattedDate, FormattedMessage, IntlShape, WrappedComponentProps } from 'react-intl';
import { parseISO } from 'date-fns';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

import { POIAndGeofenceTableToolbar } from './POIAndGeofenceTableToolbar';
import { categoryFormatter, createButtonFormatter, typeFormatter } from './tableUtils';
import CreateButtonDropdown from './CreateButtonDropdown';
import { NavigateFunction } from 'react-router';

const columnsDetails = [
    {
        name: 'name',
        width: 200,
        label: 'fleetmonitor.name',
    },
    {
        name: 'address',
        width: 250,
        label: 'intl-msg:address',
    },
    {
        name: 'type',
        width: 100,
        label: 'intl-msg:type',
    },
    {
        name: 'active',
        width: 50,
        label: 'fleetmonitor.geofenceRulesActive',
    },
    {
        name: 'category',
        width: 100,
        label: 'intl-msg:categories',
    },
    {
        name: 'updateDate',
        width: 100,
        label: 'fleetmonitor.updateDate',
    },
    {
        name: 'editAndDelete',
        width: 100,
        label: ' ',
    },
];

export interface LegacyPOI {
    [key: string]: string | number | boolean;

    name: string;
    address: string;
    updateDate: string;
    updateDateSort: string;
    radius: string;
    radiusSort: number;
    active: boolean;
    category: string;
    geofenceId: string;
    centerLat: number;
    centerLng: number;
}

interface IProps {
    listOfPOIsAndGeofences: Array<LegacyPOI>;
    showDeleteConfirmation: (poiId: string) => void;
    showUpdateDialog: (poiId: string) => void;
    geofenceEditHandler: (geofenceId: string, navigate: NavigateFunction) => void;
    refreshPoiTableData: () => void;
    allowedToCreateGeofence: boolean;
    isPoisLoading: boolean;
    isGeofencesLoading: boolean;
    navigate: NavigateFunction;
}

interface IState {
    viewType: string;
    sortName: string;
    sortOrder: string;
    searchValue: string;
    currentPage: number;
}

const options = {
    defaultSortName: 'name',
    defaultSortOrder: 'asc',
};

export class POIAndGeofenceTable extends React.Component<IProps & WrappedComponentProps & NavigateFunction, IState> {
    static PAGE_SIZE = 10;

    searchFields: Array<string>;

    filteredAndSorted: {
        list: Array<LegacyPOI>;
        sortName: string;
        sortOrder: string;
    };

    constructor(props: any) {
        super(props);
        this.state = {
            viewType: TableViewToggles.VIEW_TYPE_TABLE,
            sortName: options.defaultSortName,
            sortOrder: options.defaultSortOrder,
            searchValue: '',
            currentPage: 0,
        };
        this.filteredAndSorted = {
            list: [],
            sortName: '',
            sortOrder: '',
        };
        this.searchFields = ['name', 'address'];
    }

    updateFilteredAndSortedData() {
        const { searchValue, sortName, sortOrder } = this.state;
        const { listOfPOIsAndGeofences } = this.props;

        const data =
            searchValue === ''
                ? listOfPOIsAndGeofences
                : listOfPOIsAndGeofences.filter((entry: any) =>
                      this.searchFields.some(
                          (searchField) =>
                              entry[searchField] && entry[searchField].toLowerCase().includes(searchValue.toLowerCase())
                      )
                  );

        const lowerCaseEntry = (entry: string | number) => {
            if (typeof entry === 'string') {
                return entry.toLowerCase();
            } else {
                return entry;
            }
        };

        const sortFunction = (item1: any, item2: any) => {
            const sortParameter1 = item1[sortName] ? lowerCaseEntry(item1[sortName]) : '';
            const sortParameter2 = item2[sortName] ? lowerCaseEntry(item2[sortName]) : '';
            return sortOrder === 'asc'
                ? sortParameter1 > sortParameter2
                    ? 1
                    : -1
                : sortParameter1 > sortParameter2
                  ? -1
                  : 1;
        };

        data.sort(sortFunction);

        this.filteredAndSorted.list = data;
        this.filteredAndSorted.sortOrder = sortOrder;
        this.filteredAndSorted.sortName = sortName;
    }

    getFilteredAndSortedSlice() {
        return this.filteredAndSorted.list.slice(
            this.state.currentPage * POIAndGeofenceTable.PAGE_SIZE,
            Math.min(this.filteredAndSorted.list.length, (this.state.currentPage + 1) * POIAndGeofenceTable.PAGE_SIZE)
        );
    }

    onSortChange = (event: any) => {
        let sortName = event.currentTarget.getAttribute('data-sortby');
        sortName = sortName === 'type' ? 'radiusSort' : sortName === 'updateDate' ? 'updateDateSort' : sortName;
        this.filteredAndSorted.sortOrder = this.getSortDir(sortName, this.state.sortName);
        this.filteredAndSorted.sortName = sortName;
        this.setState({
            sortName: this.filteredAndSorted.sortName,
            sortOrder: this.filteredAndSorted.sortOrder,
        });
        this.updateFilteredAndSortedData();
    }

    getSortDir(sortBy: any, previousSortBy: any) {
        if (sortBy === previousSortBy) {
            return this.state.sortOrder === 'asc' ? 'desc' : 'asc';
        }
        return 'asc';
    }

    onSearchChange = (str: string) => {
        this.setState((previousState) => ({ ...previousState, searchValue: str, currentPage: 0 }));
        this.updateFilteredAndSortedData();
    }

    onNextPage = () => {
        this.setState((previousState) => ({ ...previousState, currentPage: previousState.currentPage + 1 }));
    }

    isNextPageDisabled = () => {
        return (this.state.currentPage + 1) * POIAndGeofenceTable.PAGE_SIZE >= this.filteredAndSorted.list.length;
    }

    onPreviousPage = () => {
        this.setState((previousState) => ({
            ...previousState,
            currentPage: Math.max(0, previousState.currentPage - 1),
        }));
    }

    isPreviousPageDisabled = () => this.state.currentPage === 0;

    handleViewTypeChange = (viewType: string) => this.setState({ viewType });

    renderTableHead = (column: any, sortName: string, sortOrder: string) => {
        const tableHeaderClassNames = classNames(
            'user-select-none sort-column text-',
            column.name === 'type' || column.name === 'category' || column.name === 'updateDate' ? 'text-center' : ''
        );

        if (column.name === 'editAndDelete') {
            return (
                <th key={column.name}>
                    <span />
                </th>
            );
        }

        const isColumnType = column.name === 'type';
        const isColumnUpdateDate = column.name === 'updateDate';

        const columnNameForDisplayingSortDir = isColumnType
            ? 'radiusSort'
            : isColumnUpdateDate
              ? 'updateDateSort'
              : column.name;

        return (
            <th
                key={column.name}
                className={tableHeaderClassNames}
                data-field={column.name}
                data-sortby={column.name}
                onClick={this.onSortChange}
            >
                <span>
                    {sortName === columnNameForDisplayingSortDir ? (
                        <SortArrows direction={sortOrder} />
                    ) : (
                        <SortArrows />
                    )}
                    {column.label !== ' ' && <FormattedMessage id={column.label} />}
                </span>
            </th>
        );
    }

    renderTableCaption = (column: any) => {
        const style =
            column && column.width
                ? {
                      minWidth: column.width,
                      width: column.width,
                  }
                : {};

        return <col key={column.name} style={style} />;
    }

    geofenceEditHandler = (geofenceId: string) => {
        this.props.geofenceEditHandler(geofenceId, this.props.navigate);
    }

    renderTableCellContent = (columnName: string, poi: LegacyPOI, intl: IntlShape) => {
        const centerClass = classNames(this.state.viewType === TableViewToggles.VIEW_TYPE_TABLE && 'text-center');

        switch (columnName) {
            case 'type':
                return <span className={centerClass}>{typeFormatter(poi.radius, poi, intl)}</span>;
            case 'active':
                if (poi[columnName]) {
                    return (
                        <div className={`${centerClass} label label-success label-condensed`}>
                            <FormattedMessage id={'fleetmonitor.active'} />
                        </div>
                    );
                }
                return (
                    <div className={`${centerClass} label label-default label-condensed`}>
                        <FormattedMessage id={'fleetmonitor.inactive'} />
                    </div>
                );
            case 'category':
                return <div className={centerClass}>{categoryFormatter(poi[columnName])}</div>;
            case 'updateDate':
                return (
                    <div className={centerClass}>
                        <FormattedDate value={parseISO(poi[columnName])} />
                    </div>
                );
            case 'editAndDelete':
                return (
                    <div>
                        {createButtonFormatter(
                            this.props.showDeleteConfirmation,
                            this.props.showUpdateDialog,
                            this.geofenceEditHandler,
                            this.props.allowedToCreateGeofence,
                            this.props.allowedToCreateGeofence,
                            poi.geofenceId,
                            poi
                        )}
                    </div>
                );
            default:
                return poi[columnName];
        }
    }

    render() {
        const { viewType, searchValue, currentPage } = this.state;
        const {
            listOfPOIsAndGeofences,
            allowedToCreateGeofence,
            refreshPoiTableData,
            isPoisLoading,
            isGeofencesLoading,
        } = this.props;

        this.updateFilteredAndSortedData();

        const tableClassNames = classNames(
            'table',
            'table-layout-fixed',
            'table-column-overflow-hidden',
            'table-bordered',
            'table-sticky',
            'table-head-filled',
            viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
            viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards'
        );

        if (isPoisLoading || isGeofencesLoading) {
            return (
                <div className={'display-flex justify-content-center align-items-center height-100pct'}>
                    <Spinner isDoubleSized={true} />
                </div>
            );
        }

        if (isEmpty(listOfPOIsAndGeofences)) {
            return (
                <EmptyState
                    headline={<FormattedMessage id={'intl-msg:table.nothing.created.headline'} />}
                    message={
                        <React.Fragment>
                            <FormattedMessage id={'intl-msg:table.nothing.created.message'} />
                            <div className={'margin-top-20'}>
                                <CreateButtonDropdown />
                            </div>
                        </React.Fragment>
                    }
                />
            );
        }

        return (
            <div>
                <POIAndGeofenceTableToolbar
                    allowedToCreateGeofence={allowedToCreateGeofence}
                    handleViewTypeChange={this.handleViewTypeChange}
                    isNextPageDisabled={this.isNextPageDisabled}
                    isPreviousPageDisabled={this.isPreviousPageDisabled}
                    onNextPage={this.onNextPage}
                    listOfPOIsAndGeofences={listOfPOIsAndGeofences}
                    onPreviousPage={this.onPreviousPage}
                    onSearchChange={this.onSearchChange}
                    refreshPoiTableData={refreshPoiTableData}
                    searchValue={searchValue}
                    currentPage={currentPage}
                />
                <div className={'table-responsive'}>
                    <table className={tableClassNames}>
                        <colgroup>{columnsDetails.map(this.renderTableCaption)}</colgroup>
                        <thead>
                            <tr>
                                {columnsDetails.map((column) =>
                                    this.renderTableHead(
                                        column,
                                        this.filteredAndSorted.sortName,
                                        this.filteredAndSorted.sortOrder
                                    )
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.getFilteredAndSortedSlice().map((poi: LegacyPOI) => (
                                <tr key={poi.geofenceId}>
                                    {columnsDetails.map((column) => {
                                        if (column.label === ' ') {
                                            return (
                                                <td key={column.name} data-field={column.label}>
                                                    <span>
                                                        {this.renderTableCellContent(column.name, poi, this.props.intl)}
                                                    </span>
                                                </td>
                                            );
                                        }
                                        return (
                                            <FormattedMessage key={column.name} id={column.label}>
                                                {(translatedLabel) => (
                                                    <td key={column.name} data-field={translatedLabel}>
                                                        <span>
                                                            {this.renderTableCellContent(
                                                                column.name,
                                                                poi,
                                                                this.props.intl
                                                            )}
                                                        </span>
                                                    </td>
                                                )}
                                            </FormattedMessage>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
