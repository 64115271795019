import {
    geofenceAllowedActionsFetched,
    geofenceDeleted,
    geofenceFetched,
    geofencesFetched,
} from '../actions/geofenceActions';

interface AbstractGeofence {
    id: string;
    name: string;
    accountId: string;
    active: boolean;
    category?: 'CUSTOMER' | 'PARTNER' | 'MY_COMPANY' | 'MISC';
    meta: {
        createdAt: string;
        modifiedAt: string;
    };
}

interface GeoCoordinate {
    latitude: number;
    longitude: number;
    address?: string;
}

export interface CircularGeofence extends AbstractGeofence {
    type: 'CIRCLE';
    radius: number;
    center: GeoCoordinate;
}

export interface PolygonalGeofence extends AbstractGeofence {
    type: 'POLYGON';
    points: Array<GeoCoordinate>;
}

export interface Geofences {
    items: Array<Geofence>;
    metaData: { allowedActions: Array<AllowedListAction> };
}

export enum AllowedListAction {
    GEOFENCE_READ = 'READ',
    GEOFENCE_CREATE = 'CREATE',
}

export type Geofence = CircularGeofence | PolygonalGeofence;

export interface GeofenceState {
    allowedActions: Array<AllowedListAction>;
    geofences: {
        [id: string]: Geofence;
    };
    isGeofencesLoading: boolean;
}

const INITIAL_STATE = {
    allowedActions: [],
    geofences: {},
    isGeofencesLoading: true,
};

const removeGeofence = (id: string, geofences: GeofenceState['geofences']) => {
    const { [id]: geofence, ...restOfGeofences } = geofences;
    return restOfGeofences;
};

export type handledActions =
    | ReturnType<typeof geofenceFetched>
    | ReturnType<typeof geofenceAllowedActionsFetched>
    | ReturnType<typeof geofencesFetched>
    | ReturnType<typeof geofenceDeleted>;

export const geofenceReducer = (state: GeofenceState = INITIAL_STATE, action: handledActions) => {
    switch (action.type) {
        case 'GEOFENCE_FETCHED':
            const geofence = action.payload;
            return { ...state, geofences: { ...state.geofences, [geofence.id]: geofence } };
        case 'GEOFENCES_FETCHED':
            const geofences: {
                [id: string]: Geofence;
            } = {};
            action.payload.forEach((item) => geofences[item.id] = item);
            return { ...state, geofences, isGeofencesLoading: false };
        case 'GEOFENCE_DELETED':
            return {
                ...state,
                geofences: removeGeofence(action.payload.id, state.geofences),
            };
        case 'GEOFENCE_ALLOWED_ACTIONS_FETCHED':
            return { ...state, allowedActions: action.payload };
        default:
            return state;
    }
};

export const getGeofence = (state: GeofenceState, id: string) => state.geofences[id] as Geofence | undefined;
export const getGeofences = (state: GeofenceState) => Object.values(state.geofences);
export const isActionAllowed = (state: GeofenceState, action: AllowedListAction) =>
    state.allowedActions.includes(action);
export const getIsGeofencesLoading = (state: GeofenceState) => state.isGeofencesLoading;
