import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { config } from '../../config';
import Header from './Header';
import { State } from '../../reducers';
import {positionSharingActions} from '../positionSharingDialog/redux/positionSharing.redux';

export interface HeaderPropertiesFromDispatch{
    showPositionSharingDialog: () => void;
}

export interface HeaderPropertiesFromState {
    homeRoute: string;
}

const mapDispatchToProps = (dispatch: Dispatch): HeaderPropertiesFromDispatch => {
   return {
       showPositionSharingDialog: () => dispatch(positionSharingActions.setShowPositionSharingDialog(true))
   };
};

const mapStateToProps = (state: State): HeaderPropertiesFromState => {
    return {
        homeRoute: config.homeRoute as string,
    };
};

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderContainer;
