import { connect } from 'react-redux';
import { isInvalid, isSubmitting, submit } from 'redux-form';

import { submitEditGeofence } from '../../actions/geofenceActions';
import { GeofenceSidebar, IGeofenceCreationSidebarProps } from './GeofenceSidebar';
import { showNotification } from '../../actions/notificationActions';
import { showDeletePOIDialog } from '../../actions/poiActions';
import { Dispatch, State } from '../../../types';
import { Routing } from '../../app/Routing';
import { FunctionProperties, NonFunctionProperties } from '../../utils/typescriptutils/typeManipulation';
import { geofenceFormElements } from './GeofenceForm';
import { NavigateFunction } from 'react-router';

export const mapStateToProps = (state: State): NonFunctionProperties<IGeofenceCreationSidebarProps> => {
    const submitDisabled =
        isInvalid(geofenceFormElements.geofenceFormName)(state) ||
        isSubmitting(geofenceFormElements.geofenceFormName)(state);

    return {
        submitDisabled,
        mode: 'UPDATE' as 'UPDATE',
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<IGeofenceCreationSidebarProps> => {
    return {
        autofillFromQueryString(): void {},
        onDelete: (geofenceId: string) => {
            dispatch(showDeletePOIDialog(geofenceId));
        },
        onClose: (navigate: NavigateFunction) => {
            navigate(Routing.poiList);
        },
        onSubmitForm: (navigate: NavigateFunction) =>
            dispatch(submitEditGeofence())
                .then(() => navigate(Routing.poiList))
                .then(() => dispatch(showNotification('intl-msg:geofence.edit.successNotification', 'success')))
                .catch(() => dispatch(showNotification('intl-msg:geofence.edit.errorNotification', 'error'))),
        onClickSubmit: () => dispatch(submit(geofenceFormElements.geofenceFormName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeofenceSidebar);
