import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { default as ClearableInputUikit } from '@rio-cloud/rio-uikit/ClearableInput';

export interface IProps {
    placeholderId?: string;
}

class ClearableInput extends React.Component<IProps & WrappedFieldProps> {
    render() {
        const {
            placeholderId,
            input: { onChange, value },
        } = this.props;
        return (
            <ClearableInputUikit
                value={value}
                onChange={onChange}
                placeholder={placeholderId && <FormattedMessage id={placeholderId} />}
            />
        );
    }
}

export default ClearableInput;
