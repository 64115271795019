import { FormattedMessage } from 'react-intl';
import SimpleButtonDropdown from '@rio-cloud/rio-uikit/SimpleButtonDropdown';
import { Routing } from '../app/Routing';
import { useNavigate } from 'react-router';
import {
    dataLayerPush,
    GAEventName,
    GAEventTrigger,
    GAFlowName,
} from '../../configuration/googleAnalytics/googleAnalytics';

export const CreateButtonDropdown = () => {
    const navigate = useNavigate();

    const navigateToPoiCreate = () => {
        navigate(Routing.poiCreate);
    };
    const navigateToGeofenceCreate = () => {
        dataLayerPush({
            trigger: GAEventTrigger.click,
            event: GAEventName.createGeofenceClicked,
            flow_name: GAFlowName.createGeofence,
        });
        navigate(Routing.geofenceCreate);
    };

    const items = [
        {
            value: <FormattedMessage id={'fleetmonitor.newPOI'} />,
            onSelect: navigateToPoiCreate,
        },
        {
            value: <FormattedMessage id={'intl-msg:newGeofence'} />,
            onSelect: navigateToGeofenceCreate,
        },
    ];
    const getTitle = () => (
        <div className={'btn-icon-right'}>
            <span className={'rioglyph rioglyph-plus-light margin-right-5'} />
            <FormattedMessage id={'intl-msg:button.createButtonDropdown.label'} />
        </div>
    );
    return <SimpleButtonDropdown bsStyle={'primary'} title={getTitle()} iconOnly={false} items={items} />;
};

export default CreateButtonDropdown;
