import thunkMiddleware from 'redux-thunk';
import { configureStore as configureStoreReduxToolkit } from '@reduxjs/toolkit';

import { rootReducer } from '../../reducers';
import { hereAutoSuggestApi } from '../../features/components/common/addressSearch/hereAutoSuggestApi';
const rtkqMiddlewares = [
    hereAutoSuggestApi.middleware,
];


const configureStore = () =>
    configureStoreReduxToolkit({
        reducer: rootReducer(),
        middleware: (getDefaultMiddleware) => getDefaultMiddleware()
            .concat(thunkMiddleware)
            .concat(rtkqMiddlewares),
    });

const store = configureStore();
// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

export { store, configureStore };
