import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import Marker from '@rio-cloud/rio-uikit/Marker';

import { State } from '../../reducers';
import { poiFormElements, POIFormValues } from '../components/poi/PoiForm';
import { Coordinates } from '../types';
import { PoiMarker } from './Markers';

interface POI {
    name: string;
    address: {
        label: string;
        position: Coordinates;
    };
}

export interface Props {
    poi?: POI;
}

class MapPoiRenderer extends React.Component<Props> {
    render() {
        const { poi } = this.props;
        if (!poi || !poi.address) {
            return null;
        }
        return (
            <Marker
                position={poi.address.position}
                icon={<PoiMarker name={poi.name} icon={'pushpin'} markerColor={'bg-map-marker-poi'} />}
            />
        );
    }
}

const mapStateToProps = (state: State) => {
    const formValues = getFormValues(poiFormElements.formName)(state) as POIFormValues;
    return {
        poi: formValues
            ? {
                  name: formValues.name,
                  address: formValues.address,
              }
            : undefined,
    };
};

const ConnectedMapPoiRenderer = connect(mapStateToProps, undefined)(MapPoiRenderer);
ConnectedMapPoiRenderer.displayName = 'ConnectedMapPoiRenderer';
export default ConnectedMapPoiRenderer;
