import { connect } from 'react-redux';
import { isInvalid, isSubmitting, submit } from 'redux-form';

import { IPOISidebarProps, POISidebar } from './POISidebar';
import { poiFormElements } from './PoiForm';
import { putPOI, showDeletePOIDialog } from '../../actions/poiActions';
import { showNotification } from '../../actions/notificationActions';
import { Dispatch, State } from '../../../types';
import { Routing } from '../../app/Routing';
import { FunctionProperties, NonFunctionProperties } from '../../utils/typescriptutils/typeManipulation';
import { NavigateFunction } from 'react-router';

export const mapStateToProps = (state: State): NonFunctionProperties<IPOISidebarProps> => {
    const invalid = isInvalid(poiFormElements.formName)(state);
    const submitting = isSubmitting(poiFormElements.formName)(state);
    return {
        mode: 'UPDATE',
        submitDisabled: invalid || submitting,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<IPOISidebarProps> => ({
    onDelete: (poiId: string) => dispatch(showDeletePOIDialog(poiId)),
    onSubmitForm: (navigate: NavigateFunction) =>
        dispatch(putPOI())
            .then(() => dispatch(showNotification('intl-msg:poi.edit.successNotification', 'success')))
            .then(() => navigate(Routing.poiList))
            .catch(() => dispatch(showNotification('intl-msg:poi.edit.errorNotification', 'error'))),
    onClickSubmit: () => dispatch(submit(poiFormElements.formName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(POISidebar);
