import { config } from '../../../config';
import { useScripts } from './useScripts';

const DEFAULT_BASE_URL = config.hereBaseUrl;

const HERE_EXTERNALS = [
    'mapsjs-core.js',
    'mapsjs-core-legacy.js',
    'mapsjs-service.js',
    'mapsjs-service-legacy.js',
    'mapsjs-mapevents.js',
    'mapsjs-ui.js',
    'mapsjs-clustering.js',
    'mapsjs-harp.js',
];

const getExternals = (scriptList: Array<string>, baseUrl: string): Array<string> =>
    scriptList.map((script) => `${baseUrl}${script}`);

const sanitizeBaseUrl = (url: string) => (url.endsWith('/') ? url : `${url}/`);

export const useHereMap = (baseUrl?: string) => {
    const hereScripts = getExternals(HERE_EXTERNALS, sanitizeBaseUrl(baseUrl || DEFAULT_BASE_URL));
    return useScripts(hereScripts, false);
};
