import { clearUploadables, setUploadables, setUploadStatus } from '../actions/uploadActions';

export interface Uploadable {
    id: string;
    type: 'POI' | 'CIRCULAR_GEOFENCE';
    name: string;
    latitude: number;
    longitude: number;
    category: 'Customer' | 'Partner' | 'My company' | 'My misc/others' | undefined;
    address: string;
    radius?: number;
    invalid?: boolean;
    status?: 'SUCCESS' | 'FAILURE';
}

interface UploadStoreState {
    uploadables: { [id: string]: Uploadable };
    processedItems: number;
}

const INITIAL_STATE = {
    uploadables: {},
    processedItems: 0,
};

export type handledActions =
    | ReturnType<typeof setUploadables>
    | ReturnType<typeof setUploadStatus>
    | ReturnType<typeof clearUploadables>;

const handleSetUploadStatus = (state: UploadStoreState, action: ReturnType<typeof setUploadStatus>) => {
    if (state.uploadables[action.payload.id]) {
        return {
            ...state,
            uploadables: {
                ...state.uploadables,
                [action.payload.id]: {
                    ...state.uploadables[action.payload.id],
                    status: action.payload.status,
                },
            },
            processedItems: state.uploadables[action.payload.id].status
                ? state.processedItems
                : state.processedItems + 1,
        };
    } else {
        return { ...state };
    }
};

const handleSetUploadables = (state: UploadStoreState, action: ReturnType<typeof setUploadables>) => {
    const uploadables: UploadStoreState['uploadables'] = {};
    action.payload.forEach((uploadable) => {
        uploadables[uploadable.id] = uploadable;
    });
    return {
        ...state,
        uploadables,
        processedItems: 0,
    };
};

export const uploadReducer = (state: UploadStoreState = INITIAL_STATE, action: handledActions) => {
    switch (action.type) {
        case 'SET_UPLOADABLES':
            return handleSetUploadables(state, action);
        case 'SET_UPLOAD_STATUS':
            return handleSetUploadStatus(state, action);
        case 'CLEAR_UPLOADABLES':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const getUploadables = (state: UploadStoreState) => Object.values(state.uploadables) as Array<Uploadable>;
export const getNumberOfUploadableItems = (state: UploadStoreState) => getUploadables(state).length;
export const getNumberOfProcessedItems = (state: UploadStoreState) => state.processedItems;
export const getSuccessfullyUploadedItems = (state: UploadStoreState) =>
    Object.values(state.uploadables).filter((uploadable) => uploadable.status === 'SUCCESS') as Array<Uploadable>;
export const getFailedUploadedItems = (state: UploadStoreState) =>
    Object.values(state.uploadables).filter((uploadable) => uploadable.status === 'FAILURE') as Array<Uploadable>;
