import { AppActions, HIDE_SESSION_EXPIRED_DIALOG } from '../app/App.actions';

export interface AppState {
    sessionExpiredAcknowledged: boolean;
    editEntity?: 'POI' | 'GEOFENCE';
    editMode?: 'CREATE' | 'UPDATE';
}

const INITIAL_STATE: AppState = {
    sessionExpiredAcknowledged: false,
    editEntity: undefined,
    editMode: undefined,
};

export type handledActions = AppActions;

const reducer = (state: AppState = INITIAL_STATE, action: handledActions): AppState => {
    switch (action.type) {
        case HIDE_SESSION_EXPIRED_DIALOG:
            return {
                ...state,
                sessionExpiredAcknowledged: true,
            };
        case 'SET_EDIT_ENTITY':
            return {
                ...state,
                editEntity: action.payload,
            };

        case 'SET_EDIT_MODE':
            return {
                ...state,
                editMode: action.payload,
            };
        default:
            return state;
    }
};

export const getSessionExpiredAcknowledged = (state: AppState) => {
    return state.sessionExpiredAcknowledged;
};

export const getEditEntity = (state: AppState) => state.editEntity;
export const getEditMode = (state: AppState) => state.editMode;

export default reducer;
