import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import isEmpty from 'lodash/fp/isEmpty';

import { trackingHelper, Trigger } from '../tracking/setupTracking';
import CreateButtonDropdown from './CreateButtonDropdown';
import { LegacyPOI, POIAndGeofenceTable } from './POIAndGeofenceTable';

export interface POIAndGeofenceTableToolbarProps {
    allowedToCreateGeofence: boolean;
    listOfPOIsAndGeofences: Array<LegacyPOI>;
    onPreviousPage: () => void;
    isPreviousPageDisabled: () => boolean;
    onNextPage: () => void;
    isNextPageDisabled: () => boolean;
    searchValue: string;
    onSearchChange: (searchValue: string) => void;
    refreshPoiTableData: () => void;
    handleViewTypeChange: (viewtype: string) => void;
    currentPage: number;
}

export const POIAndGeofenceTableToolbar = (props: POIAndGeofenceTableToolbarProps) => {
    const {
        allowedToCreateGeofence,
        listOfPOIsAndGeofences,
        currentPage,
        searchValue,
        onPreviousPage,
        isPreviousPageDisabled,
        onNextPage,
        isNextPageDisabled,
        onSearchChange,
        refreshPoiTableData,
        handleViewTypeChange,
    } = props;

    const numberOfPoisAndGeofences = listOfPOIsAndGeofences.length;

    const tracking = trackingHelper('geofencing, table');

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column min-width-200">
                        {allowedToCreateGeofence && <CreateButtonDropdown />}
                    </div>
                </div>

                <div className="table-toolbar-group-right">
                    <div className="table-toolbar-column">
                        <div className="btn-toolbar">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-label="previous"
                                {...tracking.getTrackingDataLabels(
                                    Trigger.click,
                                    'previousPage',
                                    `totalPages::${
                                        numberOfPoisAndGeofences / POIAndGeofenceTable.PAGE_SIZE
                                    }, currentPage::${currentPage}`
                                )}
                                onClick={onPreviousPage}
                                disabled={isPreviousPageDisabled()}
                            >
                                <FormattedMessage id="intl-msg:previous" />
                            </button>
                            {!isEmpty(listOfPOIsAndGeofences) && (
                                <span
                                    data-trackable="visibility"
                                    data-tracking="poi-table-total-items"
                                    data-tracking-meta={`total-${numberOfPoisAndGeofences}`}
                                    data-tracking-value={numberOfPoisAndGeofences}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        data-label="next"
                                        {...tracking.getTrackingDataLabels(
                                            Trigger.click,
                                            'nextPage',
                                            `totalPages::${
                                                numberOfPoisAndGeofences / POIAndGeofenceTable.PAGE_SIZE
                                            }, currentPage::${currentPage}`
                                        )}
                                        onClick={onNextPage}
                                        disabled={isNextPageDisabled()}
                                    >
                                        <FormattedMessage id="intl-msg:next" />
                                    </button>
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="table-toolbar-column">
                        <TableSearch value={searchValue} onChange={onSearchChange} />
                    </div>

                    <div className="table-toolbar-column table-toolbar-column-spacer">
                        <TableViewToggles onViewTypeChange={handleViewTypeChange} />
                    </div>

                    <div className="table-toolbar-column">
                        <button
                            type="button"
                            className="btn btn-default btn-icon-only"
                            onClick={refreshPoiTableData}
                            {...tracking.getTrackingDataLabels(Trigger.click, 'refreshPoiTable')}
                        >
                            <span className="rioglyph rioglyph-refresh" />
                        </button>
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};
