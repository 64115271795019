import './polyfills';

import React from 'react';
import { Provider } from 'react-redux';

import { handleLoginRedirect, main, store } from './configuration';

import { config } from './config';
import AppContainer from './features/app/App.container';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { ConfigCatProvider, createConsoleLogger, DataGovernance, LogLevel, PollingMode } from 'configcat-react';
// tslint:disable-next-line:no-submodule-imports
import { createRoot } from 'react-dom/client';

const renderApplication = () => {
    const container = document.getElementById('root');
    if (!container) {
        throw new Error('Failed to find the root element');
    }
    const root = createRoot(container);
    root.render(
        <ErrorBoundary>
            <ConfigCatProvider
                sdkKey={config.configCat.apiKey}
                pollingMode={PollingMode.AutoPoll}
                options={{
                    pollIntervalSeconds: 15 * 60, // 15 minutes
                    dataGovernance: DataGovernance.EuOnly,
                    logger: createConsoleLogger(LogLevel.Off),
                }}
            >
                <Provider store={store}>
                    <AppContainer />
                </Provider>
            </ConfigCatProvider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
