import * as React from 'react';
import { connect } from 'react-redux';

import { Dispatch, State } from '../../types';
import { fetchGeoBooked } from '../actions/bookingsActions';
import { NoProductBooking } from '../app/NoProductBooking';
import POIAdministration from '../legacy/components/PoiAdministration';
import { hasGeoBooking, isLoadingBookings } from '../reducers/selectors';

interface Props {
    hasAccess: boolean;
    isLoading: boolean;
    fetchGeoBooked: () => void;
}

export class POIPage extends React.Component<Props> {
    componentDidMount(): void {
        this.props.fetchGeoBooked();
    }

    render() {
        if (this.props.isLoading) {
            return null;
        }
        return (
            <div className={'module-content'}>
                {this.props.hasAccess ? <POIAdministration /> : <NoProductBooking />}
            </div>
        );
    }
}

export const mapStateToProps = (state: State) => {
    return {
        hasAccess: hasGeoBooking(state),
        isLoading: isLoadingBookings(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchGeoBooked: () => {
        dispatch(fetchGeoBooked());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(POIPage);
