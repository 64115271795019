import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { default as CheckboxUikit } from '@rio-cloud/rio-uikit/Checkbox';

interface CustomProps {
    checkboxLabel?: React.ReactNode;
}

class Checkbox extends React.Component<CustomProps & WrappedFieldProps> {
    render() {
        const { input: { value, onChange }, checkboxLabel } = this.props;
        return (
            <CheckboxUikit checked={value} onClick={onChange}>
                {checkboxLabel}
            </CheckboxUikit>
        );
    }
}

export default Checkbox;
