import { POI } from '../reducers/poiReducer';
import { POIFormValues } from '../components/poi/PoiForm';
import { Uploadable } from '../reducers/uploadReducer';
import { mapCategoriesFromPOIDtoToPOI } from './CategoryMapper';
import { v4 as uuid } from 'uuid';

export const mapFromPOIDtoToPOI = (poi: CustomerPOIDto): POI => ({
    id: poi.id,
    name: poi.name ? poi.name : poi.id,
    address: poi.address,
    coordinates: {
        latitude: poi.coordinates.latitude,
        longitude: poi.coordinates.longitude,
    },
    type: poi.type,
    category: mapCategoriesFromPOIDtoToPOI(poi.category),
    metadata: {
        created_at: poi.metadata.created_at,
        modified_at: poi.metadata.modified_at,
    },
});

export interface POIDto {
    id: string;
    name?: string;
    type: 'CUSTOMER' | 'WORKSHOP';
    coordinates: {
        latitude: number;
        longitude: number;
    };
    metadata: {
        created_at: string;
        modified_at: string;
    };
}

export interface CustomerPOIDto extends POIDto {
    address: string;
    category?: 'CUSTOMER' | 'PARTNER' | 'MY_COMPANY' | 'MISC';
}

export interface WorkshopPOIDto extends POIDto {
    workshop_id: string;
    mobile24?: boolean;
    address?: {
        country: string;
        street?: string;
        zip?: string;
        city?: string;
    };
    contact?: {
        email: string;
        phone: string;
    };
}

export const mapFormValuesToCustomerPOIDto = (formValues: POIFormValues): CustomerPOIDto =>
    ({
        id: formValues.id ? formValues.id : uuid(),
        name: formValues.name,
        category: formValues.category,
        address: formValues.address.label,
        type: 'CUSTOMER',
        coordinates: {
            latitude: formValues.address.position.lat,
            longitude: formValues.address.position.lng,
        },
    }) as unknown as CustomerPOIDto;

const mapCategories = (category: Uploadable['category']): CustomerPOIDto['category'] => {
    switch (category) {
        case 'Customer':
            return 'CUSTOMER';
        case 'My company':
            return 'MY_COMPANY';
        case 'Partner':
            return 'PARTNER';
        case 'My misc/others':
            return 'MISC';
        default:
            return undefined;
    }
};

export const mapUploadableToCustomerPOIDto = (uploadable: Uploadable): CustomerPOIDto => {
    if (uploadable.type !== 'POI') {
        throw Error('mapUploadableToCustomerPOIDto can only map POIs');
    }
    return {
        id: uploadable.id,
        name: uploadable.name,
        type: 'CUSTOMER',
        coordinates: {
            latitude: uploadable.latitude,
            longitude: uploadable.longitude,
        },
        address: uploadable.address,
        category: mapCategories(uploadable.category),
    } as unknown as CustomerPOIDto;
};

export const mapPOIToTableObject = (poi: CustomerPOIDto) => ({
    geofenceId: poi.id,
    name: poi.name,
    vehicleIds: [],
    updateDate: poi.metadata.modified_at,
    updateDateSort: poi.metadata.modified_at,
    active: false,
    category: poi.category,
    address: poi.address,
    centerLat: poi.coordinates.latitude,
    centerLng: poi.coordinates.longitude,
});
