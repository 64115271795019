import * as fromAppReducer from './appReducer';
import * as fromPOIReducer from './poiReducer';
import * as fromMapReducer from './mapReducer';
import * as fromGeofenceReducer from './geofenceReducer';
import * as fromUploadReducer from './uploadReducer';
import * as fromBookingsReducer from './bookingsReducer';
import { State } from '../../types';

export const getDeletePoiGeofenceId = (state: State) => state.poi.deletePoiGeofenceId;
export const getDeletePoi = (state: State) => {
    const deletePoiId = getDeletePoiGeofenceId(state);
    if (deletePoiId) {
        return getPoi(state, deletePoiId);
    }
};
export const getDeleteGeofence = (state: State) => {
    const deleteGeofenceId = getDeletePoiGeofenceId(state);
    if (deleteGeofenceId) {
        return getGeofence(state, deleteGeofenceId);
    }
};

const getPOIsSlice = (state: State) => state.poi;
export const getPoi = (state: State, id: string) => fromPOIReducer.getPoi(getPOIsSlice(state), id);
export const getPois = (state: State) => fromPOIReducer.getPois(getPOIsSlice(state));
export const getIsPoisLoading = (state: State) => fromPOIReducer.getIsPoisLoading(getPOIsSlice(state));

const getMapSlice = (state: State) => state.newMap;
export const getZoom = (state: State) => fromMapReducer.getZoom(getMapSlice(state));
export const getMapCenter = (state: State) => fromMapReducer.getMapCenter(getMapSlice(state));
export const getMapBoundingBox = (state: State) => fromMapReducer.getMapBoundingBox(getMapSlice(state));
export const getMapStyle = (state: State) => fromMapReducer.getMapStyle(getMapSlice(state));

const getGeofenceSlice = (state: State) => state.geofence;
export const getGeofence = (state: State, id: string) => fromGeofenceReducer.getGeofence(getGeofenceSlice(state), id);
export const getGeofences = (state: State) => fromGeofenceReducer.getGeofences(getGeofenceSlice(state));
export const isGeofenceActionAllowed = (state: State, action: fromGeofenceReducer.AllowedListAction) =>
    fromGeofenceReducer.isActionAllowed(getGeofenceSlice(state), action);
export const getIsGeofencesLoading = (state: State) =>
    fromGeofenceReducer.getIsGeofencesLoading(getGeofenceSlice(state));

const getUploadSlice = (state: State) => state.upload;
export const getUploadables = (state: State) => fromUploadReducer.getUploadables(getUploadSlice(state));
export const getNumberOfProcessedItems = (state: State) =>
    fromUploadReducer.getNumberOfProcessedItems(getUploadSlice(state));
export const getFailedUploadedItems = (state: State) => fromUploadReducer.getFailedUploadedItems(getUploadSlice(state));

const getBookingsSlice = (state: State) => state.bookings;
export const isLoadingBookings = (state: State) => fromBookingsReducer.isLoading(getBookingsSlice(state));
export const hasGeoBooking = (state: State) => fromBookingsReducer.hasGeoBooking(getBookingsSlice(state));

const getAppSlice = (state: State) => state.app;
export const getSessionExpiredAcknowledged = (state: State) =>
    fromAppReducer.getSessionExpiredAcknowledged(getAppSlice(state));
export const getEditEntity = (state: State) => fromAppReducer.getEditEntity(getAppSlice(state));
export const getEditMode = (state: State) => fromAppReducer.getEditMode(getAppSlice(state));
