import { v4 as uuid } from 'uuid';

import { Geofence, AllowedListAction, Geofences } from '../reducers/geofenceReducer';
import { mapCategory, mapToDtoCategory } from './CategoryMapper';
import { Coordinates } from '../types';

interface AbstractGeofenceDto {
    id: string;
    name: string;
    active: boolean;
    category: string | undefined;
}

interface GeoCoordinateDto {
    longitude: number;
    latitude: number;
    address?: string;
}

interface PolygonalGeofencePutDto extends AbstractGeofenceDto {
    type: 'POLYGON';
    points: Array<GeoCoordinateDto>;
}

export interface CircularGeofencePutDto extends AbstractGeofenceDto {
    type: 'CIRCLE';
    center: GeoCoordinateDto;
    radius: number;
}

// type GeofencePutDto = PolygonalGeofencePutDto | CircularGeofencePutDto;

interface MetaDto {
    created_at: string;
    modified_at: string;
}

interface PolygonalGeofenceDto extends PolygonalGeofencePutDto {
    metadata: MetaDto;
    account_id: string;
}

interface CircularGeofenceDto extends CircularGeofencePutDto {
    metadata: MetaDto;
    account_id: string;
}

type GeofenceDto = PolygonalGeofenceDto | CircularGeofenceDto;

type GeofenceCategory = 'CUSTOMER' | 'PARTNER' | 'MY_COMPANY' | 'MISC';

export interface GeofencesDto {
    items: Array<GeofenceDto>;
    metadata: {
        allowed_actions: Array<string>;
    };
}

export interface IGeofenceFormValues {
    geofenceId?: string;
    geofenceName: string;
    activateGeofence: boolean;
    geofenceCategory: GeofenceCategory;
}

export interface IPolygonalGeofenceFormValues extends IGeofenceFormValues {
    points: Array<Coordinates>;
    geofenceShape: 'polygonal';
}

export interface ICircularGeofenceFormValues extends IGeofenceFormValues {
    points: Array<Coordinates>;
    geofenceRadius: number;
    geofenceAddress: string;
    geofenceShape: 'circular';
}

export const mapToGeofenceDto = (
    values: IPolygonalGeofenceFormValues | ICircularGeofenceFormValues
): PolygonalGeofencePutDto | CircularGeofencePutDto => {
    if (values.geofenceShape === 'polygonal') {
        return {
            id: uuid(),
            name: values.geofenceName,
            type: 'POLYGON',
            active: values.activateGeofence,
            points: values.points.map((point) => ({ longitude: point.lng, latitude: point.lat })),
            category: mapToDtoCategory(values.geofenceCategory),
        };
    } else {
        return {
            id: uuid(),
            name: values.geofenceName,
            type: 'CIRCLE',
            active: values.activateGeofence,
            center: {
                longitude: values.points[0].lng,
                latitude: values.points[0].lat,
                address: values.geofenceAddress,
            },
            radius: values.geofenceRadius,
            category: mapToDtoCategory(values.geofenceCategory),
        };
    }
};

export interface GeofenceEditFieldsDto {
    id?: string;
    name: string;
    active: boolean;
    category?: GeofenceCategory;
}

export const mapToEditGeofenceDto = (values: IGeofenceFormValues): GeofenceEditFieldsDto => {
    return {
        id: values.geofenceId,
        name: values.geofenceName,
        active: values.activateGeofence,
        category: values.geofenceCategory,
    };
};

const mapMetaDataDto = (metadata: { allowed_actions: Array<string> }) => {
    return {
        allowedActions: metadata.allowed_actions.reduce((mappedAllowedActions: Array<AllowedListAction>, allowedAction) => {
            if (allowedAction.toLowerCase().match('geofence.read')) {
                mappedAllowedActions.push(AllowedListAction.GEOFENCE_READ);
            } else if (allowedAction.toLowerCase().match('geofence.create')) {
                mappedAllowedActions.push(AllowedListAction.GEOFENCE_CREATE);
            }
            return mappedAllowedActions;
        }, []),
    };
};

export const mapFromGeofencesDto = (geofencesDto: GeofencesDto): Geofences => {
    return {
        items: geofencesDto.items.map((geofence) => mapFromGeofenceDto(geofence)),
        metaData: mapMetaDataDto(geofencesDto.metadata),
    };
};

export const mapFromGeofenceDto = (geofenceDto: GeofenceDto): Geofence => {
    if (geofenceDto.type === 'POLYGON') {
        return mapFromPolygonalGeofenceDto(geofenceDto);
    }
    return mapFromCircularGeofenceDto(geofenceDto);
};

const mapFromPolygonalGeofenceDto = (polygonalGeofenceDto: PolygonalGeofenceDto) => {
    return {
        ...mapFromAbstractGeofenceDto(polygonalGeofenceDto),
        type: 'POLYGON' as 'POLYGON',
        points: polygonalGeofenceDto.points.map((geoCoordinateDto) => mapFromGeoCoordinateDto(geoCoordinateDto)),
    };
};

const mapFromAbstractGeofenceDto = (geofenceDto: GeofenceDto) => {
    return {
        id: geofenceDto.id,
        name: geofenceDto.name,
        category: mapCategory(geofenceDto.category),
        active: geofenceDto.active,
        accountId: geofenceDto.account_id,
        meta: mapMetaDto(geofenceDto.metadata),
    };
};

const mapMetaDto = (metaDto: MetaDto) => ({
    createdAt: metaDto.created_at,
    modifiedAt: metaDto.modified_at,
});

const mapFromGeoCoordinateDto = (geoCoordinateDto: GeoCoordinateDto) => ({
    latitude: geoCoordinateDto.latitude,
    longitude: geoCoordinateDto.longitude,
    address: geoCoordinateDto.address,
});

const mapFromCircularGeofenceDto = (circularGeofenceDto: CircularGeofenceDto) => {
    return {
        ...mapFromAbstractGeofenceDto(circularGeofenceDto),
        type: 'CIRCLE' as 'CIRCLE',
        radius: circularGeofenceDto.radius,
        center: mapFromGeoCoordinateDto(circularGeofenceDto.center),
    };
};

export const mapGeofenceToTableObject = (geofence: any) => {
    const baseLegacyPOI = {
        geofenceId: geofence.id,
        name: geofence.name,
        vehicleIds: [],
        updateDate: geofence.meta.modifiedAt,
        updateDateSort: geofence.meta.modifiedAt,
        active: geofence.active,
        category: geofence.category,
    };
    if (geofence.type === 'CIRCLE') {
        return {
            ...baseLegacyPOI,
            address: geofence.center.address,
            centerLat: geofence.center.latitude,
            centerLng: geofence.center.longitude,
            radius: geofence.radius && `${geofence.radius} m`,
            radiusSort: geofence.radius || 0,
        };
    }
    if (geofence.type === 'POLYGON') {
        let sumLatitude = 0;
        let sumLongitude = 0;
        geofence.points.forEach(({ latitude, longitude }: { latitude: number; longitude: number }) => {
            sumLatitude += latitude;
            sumLongitude += longitude;
        });
        const centerLat = sumLatitude / geofence.points.length;
        const centerLng = sumLongitude / geofence.points.length;
        return {
            ...baseLegacyPOI,
            address: `${centerLat}, ${centerLng}`,
            centerLat,
            centerLng,
            radius: -42,
            radiusSort: -42,
        };
    }
};
