import React from 'react';
import * as Sentry from '@sentry/browser';

import { MapErrorState } from './MapErrorState';

interface IProps {
    children?: React.ReactNode;
}

interface IState {
    error?: Error;
    eventId?: string;
}

export class MapErrorBoundary extends React.PureComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: undefined,
            eventId: undefined,
        };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({ error });

        if (import.meta.env.PROD) {
            Sentry.withScope((scope) => {
                Object.keys(errorInfo).forEach((key) => scope.setExtra(key, errorInfo[key]));
                const eventId = Sentry.captureException(error);
                this.setState({ eventId });
            });
        }
    }

    render() {
        const { error, eventId } = this.state;
        const { children } = this.props;

        if (error) {
            return <MapErrorState onButtonClick={() => Sentry.showReportDialog({ eventId })} />;
        }
        return children;
    }
}
