
import MapWrapper from './MapWrapper';
import { MapErrorBoundary } from './MapErrorBoundary';
import { MainLoadingIndicator } from '../app/MainLoadingIndicator';
import MapLoader from './MapLoader';

export const Map = () => {
    const mapClassName = 'GeofenceAdministrationMap height-100pct';

    return (
        <div className={mapClassName}>
                <MapErrorBoundary>
                    <MapLoader fallback={<MainLoadingIndicator />}>
                        <MapWrapper />
                    </MapLoader>
                </MapErrorBoundary>
        </div>
    );
};
