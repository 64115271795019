import { Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Map from '@rio-cloud/rio-uikit/Map';
import MapSettings from '@rio-cloud/rio-uikit/MapSettings';
import MapTypeSettings from '@rio-cloud/rio-uikit/MapTypeSettings';
import EventUtils from '@rio-cloud/rio-uikit/EventUtils';

import { Dispatch, State } from '../../types';
import { handleTapEvent, setMapCenter, setMapZoom } from '../actions/mapActions';
import { getMapBoundingBox, getMapCenter, getZoom } from '../reducers/selectors';
import MapGeofenceRenderer from './MapGeofenceRenderer';
import MapPoiRenderer from './MapPoiRenderer';
import { MapApi, MapEvent } from '@rio-cloud/rio-uikit/mapTypes';
import { FormattedMessage, useIntl } from 'react-intl';
import { config } from '../../config';

const resizeMap = (api: any) => {
    // TODO: check performance fo unnecessary resize on every render - solution might be to check
    // whether or not the AssetTree and/or the Asset details sidebar are shown
    api.map.getViewPort().resize();
};

const MapComponent = (props: Props) => {
    const { center, zoom, setZoom, setCenter, boundingBox, doHandleTapEvent } = props;
    const intl = useIntl();
    const hereCredentials = {apikey: config.hereApiKey};

    const eventListenerMap = {
        [EventUtils.TAP]: (event: Event, enhancedMapEvent: MapEvent) => {
            doHandleTapEvent(enhancedMapEvent);
        },
        [EventUtils.MAP_VIEW_CHANGE_END]: (event: Event) => {
            const target: any = event.currentTarget;

            const updatedCenter = target.getCenter();
            const newZoom = target.getZoom();

            setCenter({
                latitude: updatedCenter.lat,
                longitude: updatedCenter.lng,
            });
            setZoom(newZoom);
        },
    };

    return (
        <Map
            credentials={hereCredentials}
            center={center}
            zoom={zoom}
            boundingBox={boundingBox}
            eventListenerMap={eventListenerMap}
            mapSettings={
                <MapSettings
                    options={[
                        <MapTypeSettings
                            key="mapTypeSettings"
                            tooltip={intl.formatMessage({
                                id: 'intl-msg:common-message.component.map.changeMapTypeTooltip',
                            })}
                            dropdownHeaderText={
                                <FormattedMessage id="intl-msg:common-message.component.map.mapViews" />
                            }
                            defaultTypeLabel={
                                <FormattedMessage id="intl-msg:common-message.component.map.defaultView" />
                            }
                            truckTypeLabel={<FormattedMessage id="intl-msg:common-message.component.map.truckView" />}
                            terrainTypeLabel={
                                <FormattedMessage id="intl-msg:common-message.component.map.terrainView" />
                            }
                            satelliteTypeLabel={
                                <FormattedMessage id="intl-msg:common-message.component.map.satelliteView" />
                            }
                            nightTypeLabel={<FormattedMessage id="intl-msg:common-message.component.map.nightView" />}
                        />,
                    ]}
                />
            }
        >
            {(mapApi: MapApi) => {
                resizeMap(mapApi);

                return (
                    <Fragment>
                        <MapPoiRenderer />
                        <MapGeofenceRenderer mapApi={mapApi} />
                    </Fragment>
                );
            }}
        </Map>
    );
};

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    center: getMapCenter(state),
    zoom: getZoom(state),
    boundingBox: getMapBoundingBox(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setZoom: (zoom: number) => dispatch(setMapZoom(zoom)),
    setCenter: (center: { latitude: number; longitude: number }) => dispatch(setMapCenter(center)),
    doHandleTapEvent: (event: MapEvent) => dispatch(handleTapEvent(event)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const MapWrapper = connector(MapComponent);
export default MapWrapper;
