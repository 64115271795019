import { connect } from 'react-redux';
import { Dispatch } from '../../../types';
import { FunctionProperties } from '../../utils/typescriptutils/typeManipulation';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { Routing } from '../../app/Routing';
import { setEditEntity, setEditMode } from '../../app/App.actions';

interface UrlParsingContainerProps {
    doSetEditMode: (editMode?: 'CREATE' | 'UPDATE') => void;
    doSetEditEntity: (editEntity?: 'POI' | 'GEOFENCE') => void;
}

const UrlParsing = (props: UrlParsingContainerProps): null => {
    const { doSetEditEntity, doSetEditMode } = props;
    const path = useLocation().pathname;
    useEffect(() => {
        if (path.match(Routing.poiCreate) || path.match(Routing.geofenceCreate)) {
            doSetEditEntity(path.match(Routing.poiCreate) ? 'POI' : 'GEOFENCE');
            doSetEditMode('CREATE');
        } else if (
            path.match(Routing.poiEdit.replace(':id', '.+')) ||
            path.match(Routing.geofenceEdit.replace(':id', '.+'))
        ) {
            doSetEditEntity(path.match(Routing.poiEdit.replace(':id', '.+')) ? 'POI' : 'GEOFENCE');
            doSetEditMode('UPDATE');
        } else {
            doSetEditMode(undefined);
            doSetEditEntity(undefined);
        }
    }, [path, doSetEditEntity, doSetEditMode]);
    return null;
};

const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<UrlParsingContainerProps> => ({
    doSetEditMode: (editMode?: 'CREATE' | 'UPDATE') => {
        dispatch(setEditMode(editMode));
    },
    doSetEditEntity: (editEntity?: 'POI' | 'GEOFENCE') => {
        dispatch(setEditEntity(editEntity));
    },
});

export const UrlParsingContainer = connect(undefined, mapDispatchToProps)(UrlParsing);
