import * as React from 'react';
import { connect } from 'react-redux';

import { Routing } from '../../app/Routing';
import POIAndGeofenceTable from '../../table/POIAndGeofenceTable.container';
import { fetchPOIs, showDeletePOIDialog } from '../../actions/poiActions';
import { getGeofences } from '../../actions/geofenceActions';
import { Dispatch } from '../../../types';
import useOnMount from '@rio-cloud/rio-uikit/hooks/useOnMount';
import { useNavigate } from 'react-router';

interface PoiAdministrationProps {
    fetchPOIData: () => void;
    showDeletePOIDialog: (poiId: string) => void;
}

const PoiAdministration = (props: PoiAdministrationProps) => {
    const navigate = useNavigate();

    const showEditPOIDialog = (poiId: string) => {
        navigate(Routing.poiEdit.replace(':id', poiId));
    };

    useOnMount(() => {
        props.fetchPOIData();
    }, []);

    return (
        <div className={'height-100pct'} data-component={'POIPage'}>
            <POIAndGeofenceTable
                showDeleteConfirmation={props.showDeletePOIDialog}
                showUpdateDialog={showEditPOIDialog}
                navigate={navigate}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): PoiAdministrationProps => ({
    fetchPOIData() {
        dispatch(fetchPOIs());
        dispatch(getGeofences());
    },
    showDeletePOIDialog(poiId) {
        dispatch(showDeletePOIDialog(poiId));
    },
});

export default connect(undefined, mapDispatchToProps)(PoiAdministration);
