import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';

import GeofenceSidebarFooter from '../geofence/GeofenceSidebarFooter'; // todo move
import PoiForm from './PoiForm';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import { Location } from 'history';
import { validate } from 'uuid';
import { Routing } from '../../app/Routing';

export interface IPOISidebarProps {
    mode: 'CREATE' | 'UPDATE';
    onDelete: (poiId: string) => void;
    onSubmitForm: (navigate: NavigateFunction) => void;
    onClickSubmit: () => void;
    submitDisabled: boolean;
    poiId?: string;
}

const resolvePoiId = (location: Location): string | undefined => {
    const possiblePoiId = location.pathname.split('/').pop();
    if (possiblePoiId && validate(possiblePoiId)) {
        return possiblePoiId;
    } else {
        return undefined;
    }
};

export const POISidebar = (props: IPOISidebarProps) => {
    const { onDelete, mode, onClickSubmit, submitDisabled, onSubmitForm } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const poiId = resolvePoiId(location);
    const handleDelete = (): void => {
        if (poiId) {
            onDelete(poiId);
        }
    };

    const onSubmit = (): void => {
        onSubmitForm(navigate);
    };

    const onClose = (): void => {
        navigate(Routing.poiList);
    };

    return (
        <Sidebar
            width={400}
            title={
                mode === 'CREATE' ? (
                    <FormattedMessage id={'intl-msg:poi:create:title'} />
                ) : (
                    <FormattedMessage id={'intl-msg:poi.edit.title'} />
                )
            }
            onClose={onClose}
            closed={false}
            titleClassName={'text-size-large margin-left-10'}
            footer={
                <GeofenceSidebarFooter
                    onDeleteHandler={handleDelete}
                    onAbortHandler={onClose}
                    onSubmitHandler={onClickSubmit}
                    submitDisabled={submitDisabled}
                    mode={mode}
                />
            }
        >
            <div className={'padding-20 padding-top-0'}>
                <PoiForm poiId={poiId} onSubmit={onSubmit} />
            </div>
        </Sidebar>
    );
};
