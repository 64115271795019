import { OnboardingState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { Tips } from '../types';
import { loadOnboardingTip, saveOnboardingTip } from '../onboardingToLocalStorage';
import { State } from '../../../reducers';

const initialState: OnboardingState = Object.values(Tips).reduce(
    (acc, tip) => ({
        ...acc,
        [tip]: loadOnboardingTip(tip),
    }),
    {}
);

const name = 'onboarding';

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setShowOnboardingTip: (
            state: OnboardingState,
            action: PayloadAction<{ type: Tips; value: boolean }>
        ): void => {
            state[action.payload.type] = action.payload.value;
            saveOnboardingTip(action.payload.type, action.payload.value);
        },
    },
});

export const onboardingActions = actions;
export const onboardingReducer = reducer;

const getBase = (state: State): OnboardingState | undefined => get(state, name);

export const getShowOnboardingTip = (state: State, tip: Tips): boolean => get(getBase(state), tip, false);
