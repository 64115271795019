import { useEffect, useState } from 'react';

const hasScriptsInHead = (src: string) => document.head.querySelectorAll(`script[src*="${src}"]`).length > 0;

export const useScripts = (scripts: Array<string>, loadAsync = false) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>();

    const promises = new Set();

    useEffect(() => {
        const [firstScript] = scripts;

        if (hasScriptsInHead(firstScript)) {
            setHasLoaded(true);
        } else {
            setIsLoading(true);

            scripts.map((external) => {
                promises.add(
                    new Promise<void>((resolve, reject) => {
                        const script = document.createElement('script');
                        script.async = loadAsync;
                        script.src = external;

                        script.onload = () => {
                            console.debug(`loaded ${external}`);
                            resolve();
                        };

                        script.onerror = () => {
                            console.debug(`failed to load ${external}`);
                            setError(new Error(`failed to load ${external}`));
                            reject();
                        };

                        document.head.appendChild(script);
                    })
                );
            });

            Promise.all([...promises]).then(
                () => {
                    setIsLoading(false);
                    setHasLoaded(true);
                },
                () => {
                    setIsLoading(false);
                    setHasLoaded(false);
                }
            );
        }
    }, []);

    return { isLoading, error, hasLoaded };
};
