import { getFormValues } from 'redux-form';
import {
    ICircularGeofenceFormValues,
    IGeofenceFormValues,
    IPolygonalGeofenceFormValues,
    mapFromGeofenceDto,
    mapFromGeofencesDto,
    mapToEditGeofenceDto,
    mapToGeofenceDto,
} from '../mapper/GeofenceMapper';
import { authenticatedDelete, authenticatedGet, authenticatedPatch, authenticatedPut } from './fetchActions';
import { showNotification } from './notificationActions';
import { AllowedListAction, Geofence } from '../reducers/geofenceReducer';
import { NotFoundError } from '../utils/notFoundError';
import { ForbiddenError } from '../utils/forbiddenError';
import { Dispatch, State } from '../../types';
import { config } from '../../config';
import { geofenceFormElements } from '../components/geofence/GeofenceForm';
import {
    dataLayerPush,
    GAEventName,
    GAEventTrigger,
    GAFlowName,
} from '../../configuration/googleAnalytics/googleAnalytics';

export const submitCreateGeofence = () => (dispatch: Dispatch, getStore: () => State) => {
    const formValues = getFormValues(geofenceFormElements.geofenceFormName)(getStore()) as
        | IPolygonalGeofenceFormValues
        | ICircularGeofenceFormValues;
    const geofenceDto = mapToGeofenceDto(formValues);
    return putGeofence(geofenceDto, dispatch).then(() => {
        const event =
            formValues.geofenceShape === 'circular'
                ? GAEventName.circularGeofenceCreated
                : GAEventName.polygonalGeofenceCreated;
        dataLayerPush({
            trigger: GAEventTrigger.click,
            event,
            flow_name: GAFlowName.createGeofence,
        });
    });
};

const putGeofence = (geofenceDto: ReturnType<typeof mapToGeofenceDto>, dispatch: Dispatch) => {
    const path = config.backend.RIO_GEOFENCEADMIN + `/geofences/${geofenceDto.id}`;
    return dispatch(authenticatedPut(path, geofenceDto));
};

export const deleteGeofence = (geofenceId: string) => (dispatch: Dispatch) => {
    const path = config.backend.RIO_GEOFENCEADMIN + `/geofences/${geofenceId}`;
    return dispatch(authenticatedDelete(path))
        .then(() => dispatch(geofenceDeleted(geofenceId)))
        .then(() => dispatch(showNotification('geofence.successfullyDeleted', 'success')));
};

export const geofenceDeleted = (geofenceId: string) => ({
    type: 'GEOFENCE_DELETED' as 'GEOFENCE_DELETED',
    payload: { id: geofenceId },
});

export const submitEditGeofence = () => (dispatch: Dispatch, getStore: () => State) => {
    const formValues = getFormValues(geofenceFormElements.geofenceFormName)(getStore()) as IGeofenceFormValues;
    const geofenceDto = mapToEditGeofenceDto(formValues);

    return patchGeofence(geofenceDto, dispatch);
};

const patchGeofence = (geofenceDto: ReturnType<typeof mapToEditGeofenceDto>, dispatch: Dispatch) => {
    const path = config.backend.RIO_GEOFENCEADMIN + `/geofences/${geofenceDto.id}`;

    return dispatch(authenticatedPatch(path, geofenceDto));
};

export const getGeofence = (geofenceId: string) => (dispatch: Dispatch) => {
    const path = config.backend.RIO_GEOFENCEADMIN + `/geofences/${geofenceId}`;

    return dispatch(authenticatedGet(path))
        .then((geofenceDto) => dispatch(geofenceFetched(mapFromGeofenceDto(geofenceDto))))
        .catch((error) => {
            if (error instanceof NotFoundError) {
                dispatch(showNotification('intl-msg:geofence.error.notfound.message', 'error', { id: geofenceId }));
            } else {
                dispatch(showNotification('intl-msg:error.general.message', 'error'));
            }
            return Promise.reject(error);
        });
};

export const geofenceFetched = (geofence: Geofence) => ({
    type: 'GEOFENCE_FETCHED' as 'GEOFENCE_FETCHED',
    payload: geofence,
});

export const geofencesFetched = (geofences: Array<Geofence>) => ({
    type: 'GEOFENCES_FETCHED' as 'GEOFENCES_FETCHED',
    payload: geofences,
});

export const getGeofences = () => (dispatch: Dispatch) => {
    const path = config.backend.RIO_GEOFENCEADMIN + '/geofences';

    return dispatch(authenticatedGet(path))
        .then((geofencesDto) => {
            const geofences = mapFromGeofencesDto(geofencesDto);
            dispatch(geofencesFetched(geofences.items));
            dispatch(geofenceAllowedActionsFetched(geofences.metaData.allowedActions));
        })
        .catch((error) => {
            if (error instanceof ForbiddenError) {
                dispatch(geofenceAllowedActionsFetched([]));
            }
            return Promise.reject(error);
        });
};

export const geofenceAllowedActionsFetched = (allowedActions: Array<AllowedListAction>) => ({
    type: 'GEOFENCE_ALLOWED_ACTIONS_FETCHED' as 'GEOFENCE_ALLOWED_ACTIONS_FETCHED',
    payload: allowedActions,
});
