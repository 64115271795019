import SingleMapMarker from '@rio-cloud/rio-uikit/SingleMapMarker';
import React from 'react';
import { MarkerColor } from '@rio-cloud/rio-uikit/components/map/utils/mapTypes';

interface PoiProps {
    name?: string;
    icon: string;
    markerColor: MarkerColor;
}

interface GeofencePointProps {
    showDragCursor?: boolean;
}

export const PoiMarker = (props: PoiProps) => {
    const { name, icon, markerColor = 'bg-map-marker-poi', ...rest } = props;
    return <SingleMapMarker name={name} iconNames={[icon]} markerColor={markerColor} {...rest} />;
};

export const GeofencePointMarker = (props: GeofencePointProps) => {
    const { showDragCursor = false } = props;
    return <SingleMapMarker markerColor="bg-map-marker-geofence" anchorSize="lg" anchorOnly={true}
                            cursor={showDragCursor ? 'cursor-grab' : undefined}/>;
};
