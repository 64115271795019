import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { arrayRemoveAll, change, clearFields, Field, formValueSelector as formValueSelectorCreator } from 'redux-form';

import {geofenceFormElements, geofenceCreationFormInitialValues} from './GeofenceForm';
import RadioButton from '../common/formInputs/RadioButton';
import { Dispatch, State } from '../../../types';

export const shape = {
    circular: 'circular',
    polygonal: 'polygonal',
};

export interface IShapeSelectorProps {
    onShapeChangeHandler: () => void;
    shape: 'polygonal' | 'circular';
}

export const ShapeSelector = (props: IShapeSelectorProps) => {
    return (
        <div className={'radio-shape-selector display-flex'}>
            <Field
                label={'Circular'}
                name={geofenceFormElements.geofenceShape}
                component={RadioButton}
                type="radio"
                value={shape.circular}
                defaultChecked={props.shape && props.shape === shape.circular}
                onChange={props.onShapeChangeHandler}
                radioLabel={<FormattedMessage id={'fleetmonitor.geofence.shape.circular'} />}
            />
            <Field
                label={'Polygonal'}
                name={geofenceFormElements.geofenceShape}
                component={RadioButton}
                type={'radio'}
                value={shape.polygonal}
                defaultChecked={props.shape && props.shape === shape.polygonal}
                onChange={props.onShapeChangeHandler}
                radioLabel={<FormattedMessage id={'fleetmonitor.geofence.shape.polygonal'} />}
            />
        </div>
    );
};

export const mapStateToProps = (state: State) => {
    const shapeFieldPath = `${geofenceFormElements.geofenceShape}`;
    const formValueSelector = formValueSelectorCreator(geofenceFormElements.geofenceFormName);
    return {
        shape: formValueSelector(state, shapeFieldPath),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    onShapeChangeHandler: () => {
        dispatch(arrayRemoveAll(geofenceFormElements.geofenceFormName, 'points'));
        dispatch(
            clearFields(
                geofenceFormElements.geofenceFormName,
                false,
                false,
                geofenceFormElements.geofenceAddress,
                geofenceFormElements.geofenceRadius
            )
        );
        //  workaround for a bug in redux-form (see https://github.com/erikras/redux-form/issues/4101 )
        dispatch(
            change(
                geofenceFormElements.geofenceFormName,
                geofenceFormElements.geofenceRadius,
                geofenceCreationFormInitialValues[geofenceFormElements.geofenceRadius]
            )
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShapeSelector);
