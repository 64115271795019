import ErrorState from '@rio-cloud/rio-uikit/ErrorState';

type MapErrorStateProps = {
    onButtonClick?: () => void;
};

export const MapErrorState = ({ onButtonClick }: MapErrorStateProps) => {
    const reportButton = {
        text: 'Report feedback',
        className: 'btn-default',
        onClick: onButtonClick,
    };

    const buttons = onButtonClick ? [reportButton] : [];

    return (
        <div className={'display-flex justify-content-center padding-top-15pct'}>
            <ErrorState
                headline={'Sorry, we could not load the Map'}
                message={
                    <div>
                        {'If this error persists, please contact the RIO Support Hotline:'}
                        <br />
                        <span className={'text-size-large margin-top-10'}>{'00800 / 22550746'}</span>
                    </div>
                }
                buttons={buttons}
            />
        </div>
    );
};
